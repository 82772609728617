import { LABELS } from './flatfileLabels'
import { escapeRegExp } from 'lodash'
import getMaxCharValidator from '../utils/getMaxCharValidator'
import getColumnWidth from '../utils/getFlatfileColumnWidth'
import validateEINRegex from '../utils/validateEIN'
import validatePlanNumber from '../utils/getPlanNumberValidator'
import createNamePrefixValidator from '../utils/getNamePrefixValidator'
import createNotApplicableValidator from '../utils/getNotApplicableValidator'
import { ReasonForDiscard } from './automatic-rollovers'
import { SEARCH_ORDER_PROHIBITED_COMPANIES } from 'config/search-services'
import { additionalInvalidSsnRegex } from '../utils/getSSNValidator'

const createSearchOrderProhibitedCompaniesValidator = () => ({
  validate: 'regex_excludes',
  regex: searchOrderProhibitedCompaniesRegex,
  regexFlags: { ignoreCase: true },
  error: ReasonForDiscard.PROHIBITED_COMPANY,
})

export const searchOrderProhibitedCompaniesRegex =
  SEARCH_ORDER_PROHIBITED_COMPANIES.map(
    (company) => `(^${escapeRegExp(company)}$)`
  ).join('|')

export const getSearchFields = () => [
  {
    label: LABELS.PLAN_FIDUCIARY_NAME,
    key: 'planSponsorName',
    type: 'string',
    constraints: [
      { type: 'required' },
      {
        type: 'external',
        validator: 'planSponsorName',
        config: {
          prohibitedCompanies: createSearchOrderProhibitedCompaniesValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PLAN_FIDUCIARY_NAME),
    },
  },
  {
    label: LABELS.COMPLETE_LEGAL_PLAN_NAME,
    key: 'planName',
    type: 'string',
    constraints: [{ type: 'required' }],
    appearance: {
      size: getColumnWidth(LABELS.COMPLETE_LEGAL_PLAN_NAME),
    },
  },
  {
    label: LABELS.PLAN_EMPLOYER_IDENTIFICATION_NUMBER,
    key: 'planEin',
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'planEin',
        config: validateEINRegex(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PLAN_EMPLOYER_IDENTIFICATION_NUMBER),
    },
  },
  {
    label: LABELS.THREE_DIGIT_PLAN_NUMBER,
    key: 'planNumber',
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'planNumber',
        config: validatePlanNumber(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.THREE_DIGIT_PLAN_NUMBER),
    },
  },
  {
    label: LABELS.PARTICIPANT_SSN,
    key: 'ssn',
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'ssn',
        config: {
          ssnValidityCheck: {
            validate: 'regex_excludes',
            regex: additionalInvalidSsnRegex,
            error: 'SSN is not valid',
          },
          formatCheck: {
            validate: 'regex_matches',
            regex: '^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$',
            error: 'Format must be XXX-XX-XXXX',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_SSN),
    },
  },
  {
    label: LABELS.PARTICIPANT_FIRST_NAME,
    key: 'firstName',
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'firstName',
        config: {
          checkNamePrefix: createNamePrefixValidator(),
          checkNotApplicable: createNotApplicableValidator(),
          createMaxCharValidator: getMaxCharValidator(40),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_FIRST_NAME),
    },
  },
  {
    label: LABELS.PARTICIPANT_MIDDLE_NAME,
    key: 'middleName',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_MIDDLE_NAME),
    },
    constraints: [
      {
        type: 'external',
        validator: 'middleName',
        config: {
          createMaxCharValidator: getMaxCharValidator(20),
        },
      },
    ],
  },
  {
    label: LABELS.PARTICIPANT_LAST_NAME,
    key: 'lastName',
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'lastName',
        config: {
          checkNotApplicable: createNotApplicableValidator(),
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_LAST_NAME),
    },
  },
  {
    label: LABELS.PARTICIPANT_ADDRESS_1,
    key: 'address1',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_ADDRESS_1),
    },
  },
  {
    label: LABELS.PARTICIPANT_ADDRESS_2,
    key: 'address2',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_ADDRESS_2),
    },
  },
  {
    label: LABELS.PARTICIPANT_ADDRESS_3,
    key: 'address3',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_ADDRESS_3),
    },
  },
  {
    label: LABELS.PARTICIPANT_CITY,
    key: 'city',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_CITY),
    },
  },
  {
    label: LABELS.PARTICIPANT_STATE,
    key: 'state',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_STATE),
    },
  },
  {
    label: LABELS.PARTICIPANT_ZIP,
    key: 'zip',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_ZIP),
    },
  },
  {
    label: LABELS.PARTICIPANT_DOB,
    key: 'dob',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_DOB),
    },
  },
  {
    label: LABELS.PARTICIPANT_DOD,
    key: 'dod',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.PARTICIPANT_DOD),
    },
  },
  {
    label: LABELS.PHONE_NUMBER,
    key: 'phone',
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'phone',
        config: {
          phoneNumber: {
            regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
            error:
              'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PHONE_NUMBER),
    },
  },
  {
    label: LABELS.WORK_FOR,
    key: 'workFor',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.WORK_FOR),
    },
  },
  {
    label: LABELS.REASON,
    key: 'reason',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.REASON),
    },
  },
  {
    label: LABELS.MISCELLANEOUS_1,
    key: 'miscellaneous1',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.MISCELLANEOUS_1),
    },
  },
  {
    label: LABELS.MISCELLANEOUS_2,
    key: 'miscellaneous2',
    type: 'string',
    appearance: {
      size: getColumnWidth(LABELS.MISCELLANEOUS_2),
    },
  },
]
