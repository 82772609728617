import { externalConstraint } from '@flatfile/plugin-constraints'
import { IRA_AMOUNT } from './config/iraAmount'
import { iraAmountValidator, recordkeeperListener } from './listeners'

export const uncashedChecksParticipantListeners = (listener) => {
  listener.use(
    externalConstraint('recordKeeper', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  recordkeeperListener(listener, 'participant-record')

  listener.use(
    externalConstraint('tpa', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planSponsor', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planName', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('state', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('zipCode', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('countryCode', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planEin', (value, key, { config, record }) => {
      if (value && !new RegExp(config.invalidEINFormat.regex).test(value)) {
        record.addError(key, config.invalidEINFormat.error)
      }
      if (value && !new RegExp(config.invalidEIN.regex).test(value)) {
        record.addError(key, config.invalidEIN.error)
      }
    })
  )

  listener.use(
    externalConstraint('planNumber', (value, key, { config, record }) => {
      if (value && new RegExp(config.invalidCharacters.regex).test(value)) {
        record.addError(key, config.invalidCharacters.error)
      }
      if (value && !new RegExp(config.mustBeThreeDigits.regex).test(value)) {
        record.addError(key, config.mustBeThreeDigits.error)
      }
    })
  )

  listener.use(
    externalConstraint('planIdNumber', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('firstName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (value && new RegExp(config.checkNamePrefix.regex, 'i').test(value)) {
        record.addError(key, config.checkNamePrefix.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('middleName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('lastName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('addressLine1', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('addressLine2', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('city', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('ssn', (value, key, { config, record }) => {
      if (value && new RegExp(config.ssnValidityCheck.regex).test(value)) {
        record.addError(key, config.ssnValidityCheck.error)
      }
    })
  )

  listener.use(
    externalConstraint('emailAddress', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint(
      'traditionalIraPreTaxAmount',
      (value, key, { config, record }) => {
        if (
          value &&
          new RegExp(config.createMaxCharValidator.regex).test(value)
        ) {
          record.addError(key, config.createMaxCharValidator.error)
        }
      }
    )
  )

  listener.use(
    externalConstraint('rothIraAmount', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('wasTaxWithheld', (value, key, { config, record }) => {
      if (value && !new RegExp(config.wasTaxWithheld.regex).test(value)) {
        record.addError(key, config.wasTaxWithheld.error)
      }
    })
  )

  iraAmountValidator(
    listener,
    'participant-record',
    [IRA_AMOUNT.ROTH],
    IRA_AMOUNT.TRADITIONAL_PRE_TAX,
    'An IRA rollover amount is required'
  )

  iraAmountValidator(
    listener,
    'participant-record',
    [IRA_AMOUNT.TRADITIONAL_PRE_TAX],
    IRA_AMOUNT.ROTH,
    'An IRA rollover amount is required'
  )
}
