import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { countBy, isEqual } from 'lodash'
import {
  Filter,
  PaginatedAndSortableTable,
  StatusBadge,
  TableHeader,
  ComponentFailure,
  Spinner,
  Tooltip,
  QuickFilterCard,
  SearchInput,
  TableHeading,
  NoTableResults,
  DownloadTableButton,
} from 'components'
import { ParticipantsFilterDialog } from '../components'
import { TableColumn } from 'lp-components'
import {
  formatDate,
  dateSorter,
  formatCurrency,
  displayAttribute,
  numberSorter,
  sanitizeForSearch,
} from 'utils'
import { selectors as apiSelectors } from 'lp-redux-api'
import {
  DistributionAccountStatus,
  RolloverAccountStatus,
  ServicesAgreementParticipantsTooltip,
} from 'config/automatic-rollovers'
import { Icon as SuccessIcon } from 'images/status-icons/checkmark.svg'
import { Icon as ChecklistIcon } from 'images/status-icons/checklist.svg'
import { Icon as ClockIcon } from 'images/status-icons/clock.svg'
import { Icon as BDSuccessIcon } from 'images/status-icons/checkmark-green.svg'
import { Icon as BDChecklistIcon } from 'images/status-icons/currency.svg'
import { Icon as BDClockIcon } from 'images/status-icons/clock-new.svg'
import { ProductAgreementType } from '../../../config/products'

const propTypes = {
  servicesAgreementParticipants: PropTypes.arrayOf(Types.rolloverAccount),
  fetchServicesAgreementParticipants: PropTypes.func.isRequired,
  isFetchingServicesAgreementParticipants: PropTypes.bool.isRequired,
  servicesAgreementId: PropTypes.string.isRequired,
  baseFilters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isBenefitDistributionsDetails: PropTypes.bool.isRequired,
  agreementsType: PropTypes.string.isRequired,
  fieldMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {
  servicesAgreementParticipants: null,
}

const TABLE_ID = 'agreements-participants-table'

const ParticipantsHeader = ({ children }) => {
  return (
    <div className="services-agreement-participants-header">
      <h2>Participants</h2>
      {children}
    </div>
  )
}

function ServicesAgreementParticipants({
  servicesAgreementParticipants,
  fetchServicesAgreementParticipants,
  isFetchingServicesAgreementParticipants,
  isBenefitDistributionsDetails,
  servicesAgreementId,
  baseFilters,
  filters,
  handleFilter,
  search,
  handleSearch,
  fieldMappings,
  agreementsType,
}) {
  const [errorExists, setErrorExists] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)

  const hasFiltersApplied = !isEqual(filters, baseFilters)

  useEffect(() => {
    async function getServicesAgreementParticipants() {
      try {
        await fetchServicesAgreementParticipants(servicesAgreementId)
      } catch {
        setErrorExists(true)
      }
    }
    // will need to add trigger-refetch logic
    if (!servicesAgreementParticipants) getServicesAgreementParticipants()
  }, [servicesAgreementParticipants])

  const noServicesAgreementParticipants = !servicesAgreementParticipants?.length

  const toggleFilter = (filterLabel) => {
    handleFilter({
      status: filters.status.includes(filterLabel)
        ? filters.status.filter((status) => status !== filterLabel)
        : filters.status.concat(filterLabel),
    })
  }

  const counts = countBy(servicesAgreementParticipants, 'status')

  const filteredParticipants = useMemo(() => {
    if (!servicesAgreementParticipants) return
    let searchResults = servicesAgreementParticipants

    // ignore hyphens and case
    const sanitizedValue = sanitizeForSearch(search)
    if (sanitizedValue !== '') {
      searchResults = searchResults.filter(({ participantName }) =>
        sanitizeForSearch(participantName).includes(sanitizedValue)
      )
    }

    if (hasFiltersApplied) {
      return searchResults.filter(({ status }) =>
        filters.status.includes(status)
      )
    }

    return searchResults
  }, [search, servicesAgreementParticipants, filters, hasFiltersApplied])

  if (isFetchingServicesAgreementParticipants)
    return (
      <div className="services-agreement-participants">
        <ParticipantsHeader />
        <Spinner />
      </div>
    )

  if (errorExists)
    return (
      <div className="services-agreement-participants">
        <ParticipantsHeader />
        <ComponentFailure />
      </div>
    )
  const AccountStatus = isBenefitDistributionsDetails
    ? DistributionAccountStatus
    : RolloverAccountStatus
  const disabled = !filteredParticipants?.length
  const showIcon = disabled

  return (
    <div className="services-agreement-participants">
      <div className="quick-filters-container">
        <div className="container">
          <div className="flex-columns">
            <div className="flex-column">
              <QuickFilterCard
                controlId={TABLE_ID}
                isActive={filters.status.includes(
                  AccountStatus.ROLLOVER_COMPLETE
                )}
                count={counts[AccountStatus.ROLLOVER_COMPLETE] ?? 0}
                label={AccountStatus.ROLLOVER_COMPLETE}
                icon={
                  agreementsType === ProductAgreementType.ARO
                    ? SuccessIcon
                    : BDSuccessIcon
                }
                onClick={() => toggleFilter(AccountStatus.ROLLOVER_COMPLETE)}
              />
            </div>
            <div className="flex-column">
              <QuickFilterCard
                controlId={TABLE_ID}
                isActive={filters.status.includes(
                  AccountStatus.READY_FOR_FUNDING
                )}
                count={counts[AccountStatus.READY_FOR_FUNDING] ?? 0}
                label={AccountStatus.READY_FOR_FUNDING}
                icon={
                  agreementsType === ProductAgreementType.ARO
                    ? ChecklistIcon
                    : BDChecklistIcon
                }
                onClick={() => toggleFilter(AccountStatus.READY_FOR_FUNDING)}
              />
            </div>
            <div className="flex-column">
              <QuickFilterCard
                controlId={TABLE_ID}
                isActive={filters.status.includes(AccountStatus.PROCESSING)}
                count={counts[AccountStatus.PROCESSING] ?? 0}
                label={AccountStatus.PROCESSING}
                icon={
                  agreementsType === ProductAgreementType.ARO
                    ? ClockIcon
                    : BDClockIcon
                }
                onClick={() => toggleFilter(AccountStatus.PROCESSING)}
              />
            </div>
          </div>
        </div>
      </div>
      <TableHeading title="Participants">
        {agreementsType === ProductAgreementType.BD &&
          !noServicesAgreementParticipants && (
            <DownloadTableButton
              data={filteredParticipants}
              fieldMappings={fieldMappings}
              fileName="Inspira Financial_Retirement Services_Benefit Distributions Participants"
              disabled={disabled}
              showIcon={showIcon}
            />
          )}
        {agreementsType === ProductAgreementType.ARO &&
          !noServicesAgreementParticipants && (
            <DownloadTableButton
              data={filteredParticipants}
              fieldMappings={fieldMappings}
              fileName="Inspira Financial_Retirement Services_ARO Participants"
              disabled={disabled}
              showIcon={showIcon}
            />
          )}
        <div className="input-container">
          <SearchInput
            type="search"
            aria-label="Search participants"
            placeholder="Search participant name"
            onChange={(e) => handleSearch(e.target.value)}
            value={search}
          />
          <Filter
            toggleMenu={() => setShowFilterModal((s) => !s)}
            isActive={hasFiltersApplied}
          />
          {showFilterModal && (
            <ParticipantsFilterDialog
              filters={filters}
              handleSubmit={(filterValues) => handleFilter(filterValues)}
              clearAllFilters={() => handleFilter(baseFilters)}
              handleClose={() => setShowFilterModal(false)}
            />
          )}
        </div>
      </TableHeading>
      <PaginatedAndSortableTable
        tableId={TABLE_ID}
        data={filteredParticipants}
        initialAscending={false}
        initialColumn="createdDate"
        parentScrollContainerSelector="#main-content"
        className="services-agreements-table"
        noRowsFoundMessage={
          <NoTableResults
            title="No results found"
            details={
              noServicesAgreementParticipants ? (
                <>
                  When a participant is added, we'll send you a notification.
                  <br />
                  You could also try adjusting your filter or search.
                </>
              ) : (
                'Try adjusting your filter or search.'
              )
            }
          />
        }
      >
        <TableColumn name="participantName" label="Participant name" />
        {agreementsType === ProductAgreementType.ARO && (
          <TableColumn name="accountType" label="Account type" />
        )}
        <TableColumn
          name="status"
          label={
            agreementsType === ProductAgreementType.ARO
              ? 'Status'
              : 'Distribution status'
          }
          format={displayAttribute}
          component={({ data, value }) => {
            const { status } = data
            const tooltipContent = ServicesAgreementParticipantsTooltip[status]
            return (
              <td className="status">
                {status ? (
                  <Tooltip
                    offset={[0, 18]}
                    content={tooltipContent}
                    ariaLabel={tooltipContent}
                  >
                    <StatusBadge status={status} />
                  </Tooltip>
                ) : (
                  <span>{value}</span>
                )}
              </td>
            )
          }}
        />
        {agreementsType === ProductAgreementType.BD && (
          <TableColumn label="Transaction type" name="accountType" />
        )}
        <TableColumn
          name="createdDate"
          label={
            agreementsType === ProductAgreementType.BD
              ? 'Completion date'
              : 'Created date'
          }
          format={formatDate}
          sortFunc={dateSorter}
        />
        <TableColumn
          label={
            agreementsType === ProductAgreementType.BD
              ? 'Gross distribution amount'
              : 'Initial investment amount'
          }
          name="initialInvestmentAmount"
          format={compose(displayAttribute, formatCurrency)}
          className="currency"
          headerComponent={TableHeader}
          sortFunc={numberSorter}
        />
      </PaginatedAndSortableTable>
    </div>
  )
}

ServicesAgreementParticipants.propTypes = propTypes
ServicesAgreementParticipants.defaultProps = defaultProps

function mapStateToProps(state, { servicesAgreementId }) {
  return {
    servicesAgreementParticipants: selectors.servicesAgreementParticipants(
      state,
      { servicesAgreementId }
    ),
    isFetchingServicesAgreementParticipants: apiSelectors.isLoading(
      state,
      apiActions.fetchServicesAgreementParticipants
    ),
  }
}

const mapDispatchToProps = {
  fetchServicesAgreementParticipants:
    apiActions.fetchServicesAgreementParticipants,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ServicesAgreementParticipants
)
