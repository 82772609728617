import { cloneDeep, isEmpty } from 'lodash'
import { getAroParticipantFields } from './config/getAroParticipantFields'
import { getUchParticipantFields } from './config/getUchParticipantFields'
import { getSearchFields } from './config/getSearchFields'
import { getBdParticipantFields } from './config/getBdParticipantFields'

// Feature flag for manual Flatfile entry
export function isManualInputEnabled() {
  return process.env.ENABLE_MANUAL_INPUT
}

export const RecordTypes = {
  SEARCH: 'search',
  ARO_PARTICIPANT: 'automaticRolloversParticipant',
  BD_PARTICIPANT: 'benefitsDistributionParticipant',
  UCH_PARTICIPANT: 'uncashedChecksParticipant',
}

export const RecordNames = {
  [RecordTypes.SEARCH]: 'Search',
  [RecordTypes.ARO_PARTICIPANT]: 'Participant',
  [RecordTypes.BD_PARTICIPANT]: 'Participant',
  [RecordTypes.UCH_PARTICIPANT]: 'Participant',
}

export const UploadInstructions = {
  [RecordTypes.SEARCH]:
    'Important reminders: 1) Submit your information by uploading your data in a .csv file or manually filling out the form below. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Any highlighted fields that aren’t resolved won’t be included in your submission.',
  [RecordTypes.ARO_PARTICIPANT]: `Important reminders: 1) You can include participants from different retirement plans and/or plan sponsors. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Files cannot contain merged cells. 5) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
  [RecordTypes.BD_PARTICIPANT]: `Important reminders: 1) Submit your information by uploading your data in a .csv file or manually filling out the form below. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting.  4) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
  [RecordTypes.UCH_PARTICIPANT]: `Important reminders: 1) You can include participants from different retirement plans and/or plan sponsors. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Files cannot contain merged cells. 5) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
}

const getFlatfileConfig = (recordType, userAudience = '') => {
  const FlatfileSchema = {
    [RecordTypes.ARO_PARTICIPANT]: getAroParticipantFields(userAudience),
    [RecordTypes.BD_PARTICIPANT]: getBdParticipantFields(),
    [RecordTypes.SEARCH]: getSearchFields(),
    [RecordTypes.UCH_PARTICIPANT]: getUchParticipantFields(userAudience),
  }
  return FlatfileSchema[recordType]
}

const flatFileUploadModalTitle = {
  [RecordTypes.SEARCH]: `Add search participants`,
  [RecordTypes.ARO_PARTICIPANT]: `Upload your participant information`,
  [RecordTypes.BD_PARTICIPANT]: `Upload your participant information`,
  [RecordTypes.UCH_PARTICIPANT]: `Upload your participant information`,
}

export const getFlatfileFields = (recordType, options = {}) => {
  const flatfileSchema = getFlatfileConfig(recordType)
  const fields = isEmpty(options) ? flatfileSchema : cloneDeep(flatfileSchema)
  return { fields }
}

export const getFlatfileSchema = (
  recordType,
  options = {},
  userAudience = ''
) => {
  const additionalOptions = Object.values(options)
  const flatfileSchema = getFlatfileConfig(recordType, userAudience)
  const fields = isEmpty(options) ? flatfileSchema : cloneDeep(flatfileSchema)

  const updatedFields = fields.map((field) => {
    const fieldToBeUpdated = additionalOptions.find(
      (option) => option.key === field.key
    )
    if (fieldToBeUpdated != undefined) {
      return { ...field, ...fieldToBeUpdated }
    }
    return field
  })

  return {
    name: flatFileUploadModalTitle[recordType],
    sheets: [
      {
        name: `${RecordNames[recordType]} Record`,
        slug: `${RecordNames[recordType].toLowerCase()}-record`,
        fields: updatedFields,
      },
    ],
  }
}
