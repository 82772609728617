import React from 'react'
import { COMPANY_NAME } from 'config/portal'

function BDAddendumLanguageOfAgreement() {
  return (
    <div className="addendum-language-of-agreement">
      <h2>ADDENDUM TO AUTOMATIC ROLLOVER SERVICES AGREEMENT </h2>
      <h3>(Benefit Distribution Services)</h3>
      <p>
        Plan Fiduciary has selected Custodian and we have agreed to provide
        services to the Plan related to making distributions from the Plan to
        Plan participants, associated with involuntary distributions of
        participants’ eligible balances in an active and/or terminating Plan, in
        each case, in accordance with the (i) terms of the Plan and (ii) a
        Participant Election (“Benefit Distribution Services”), pursuant to the
        terms and conditions of this Addendum, as an additional service adjunct
        to the automatic rollover IRA services provided under the Automatic
        Rollover Services Agreement between Plan Fiduciary and Custodian
        (“Agreement”). Capitalized terms have the meaning given them in the
        Agreement unless otherwise defined in this Addendum.
      </p>
      <p>
        <b>1. Participant Benefit Distribution Services.</b> The Plan provides
        for certain involuntary distributions of participants’ balances in an
        active and/or terminating Plan. In those situations where a Plan
        participant has made a Participant Election, you or your authorized
        agent will direct us to distribute such electing participant’s eligible
        balance from the Plan pursuant to the Participant Election, the terms
        and conditions of the Plan and applicable Laws (“Distribution”). All
        Plan funds that you transfer to us for a Distribution must otherwise be
        eligible for a direct rollover to an IRA and satisfy the Safe Harbor
        requirements that may govern your responsibilities with respect to
        involuntary distributions from the Plan. We will distribute Plan funds
        pursuant to your direction, as provided in this Addendum.
      </p>
      <p>
        Plan funds you transfer to us will be held by us in a custody account
        opened on behalf of the Plan to receive Plan funds (“Plan Custody
        Account” or “PCA”) pending (i) completion of Distributions, (ii)
        distribution to an IRA custodied by us if a Distribution cannot be
        completed, or (iii) return to the Plan, as provided in this Addendum. We
        will make Distributions to participants from the PCA and provide
        Internal Revenue Service (“IRS”) 1099R reporting and federal and
        mandatory state tax withholding services under our tax identification
        number (“TIN”) for Distributions as directed by you or your authorized
        agent, using information provided by you.
      </p>
      <p>
        <b>2. Your Responsibilities.</b> You or your authorized agent will
        direct us to open the applicable PCA and the services to be provided for
        each Distribution on behalf of the Plan. The direction will include
        information requested by us necessary to process each Distribution,
        which includes without limitation:
      </p>
      <p>(a) delivery instructions for each Distribution; </p>
      <p>
        (b) participant’s (i) full name, (ii) full date of birth, (iii) current
        address on the books and records of the Plan, (iv) complete United
        States Social Security Number (“Participant Information”);{' '}
      </p>
      <p>
        (c) information on the amount of each Distribution (which shall be in
        cash only);{' '}
      </p>
      <p>
        (d) information regarding (i) federal tax withholding, if any,
        specifying the amount of such federal tax withholding either as a dollar
        amount or a percentage of the Distribution and (ii) dollar
        amount/percentage and state for state tax withholding, if any;
      </p>
      <p>
        (e) direction for the deduction from each Distribution of Custodian’s
        fees for Benefit Distribution Services, authorized agent fee and any
        other fees, if applicable; and
      </p>
      <p>
        (f) any information requested by us to assist us in processing the
        Distribution, Form 1099-R reporting and any federal and /or state tax
        withholding and reporting for each participant receiving a Distribution
        ((a) through (f) collectively, “Distribution Instructions”, as may be
        updated from time to time).
      </p>
      <p>
        We will not confirm receipt of all necessary Distribution Instructions
        but will advise you or your authorized agent of additional information
        needed to process a Distribution. We will have no obligation to provide
        services for any Distribution with respect to which we have received
        insufficient Distribution Instructions.
      </p>
      <p>
        You direct us to open the applicable PCA for the Plan, which will be
        held by us at a bank selected by us and is further described in Section
        5 below.
      </p>
      <p>
        You further direct us to use our TIN for any federal and / or state tax
        withholding and reporting services directed by you for each
        Distribution. We are not responsible for calculating the amount of
        federal tax withholding or any applicable state tax withholding
        applicable to a Distribution and will process Distributions strictly as
        directed by you in the Distribution Instructions.
      </p>
      <p>
        You or your authorized agent will upload the Distribution Instructions,
        together with the corresponding Distribution amount applicable to each
        participant, to us by electronic data file in a format acceptable to us.
        You agree to aggregate the Plan funds transferred to us for
        Distributions and send them to us via wire transfer, or other method as
        we may require. Each transfer of an electronic file and corresponding
        Plan funds for Distributions will serve as evidence of (i) your
        direction to us to provide Benefit Distribution Services pursuant to the
        Distribution Instructions included in such electronic files and as (ii)
        your representation that each Distribution complies with (a) the terms
        of the Plan, (b) directions given to Plan or Participant Elections made
        by or for a participant, and (c) all applicable Laws.
      </p>
      <p>
        <b>3. Our Responsibilities.</b> In the event we are unable to complete a
        Distribution pursuant to Distribution Instructions due to a failed wire,
        ACH or other electronic transfer, or a check is returned as
        undeliverable or remains uncashed for a time period exceeding the period
        allowed by our procedure in effect at the time (“Failed Distribution”),
        we will advise you and you will provide us with updated Distribution
        Instructions, which may include return of funds to the Plan. If we do
        not receive timely updated Distribution Instructions or are not able to
        complete a Distribution pursuant to updated Distribution Instructions,
        you direct us to (i) send a notice to participant at the Participant
        Information address, advising of the Failed Distribution and that an IRA
        will be opened on behalf of participant to receive Distribution funds if
        Distribution is not timely completed as provided in the notice
        (“Notice”); and (ii) open an IRA custodied by us pursuant to the
        Agreement on behalf of the Plan participant to receive Plan funds
        representing the Failed Distribution, if applicable.
      </p>
      <p>
        We will not store or maintain your electronic data file(s), Participant
        Information or Reports (collectively, “Plan Data”) on your behalf once
        Reports are available to you via our online portal, and any Plan Data
        submitted to us related to a specific search may be deleted 30 days
        after the relevant Report is available. Plan Data cannot be recovered
        once deleted from the Services. You acknowledge that you are solely
        responsible for the maintenance of Plan Data and Report(s) after
        deletion from the Services, and we shall not be liable for any loss or
        destruction of Plan Data as provided herein.
      </p>
      <p>
        If Failed Distribution results in the establishment of an IRA during the
        Distribution calendar year taxable year, we will correct the tax
        withholding and reporting for the Failed Distribution, prior to opening
        the IRA. If Failed Distribution results in the establishment of an IRA
        in the year following the Distribution calendar year taxable year, you
        acknowledge and agree that we are unable to correct the tax withholding
        and reporting, if any, and cannot recover taxes paid from the
        Distribution prior to opening the IRA.
      </p>
      <p>
        <b>4. Our Responsibilities. </b> Upon receipt of Distribution
        Instructions (initial or updated) which serve as evidence of your
        direction to us to perform Benefit Distribution Services, we will:
      </p>
      <p>
        (a) Process the Distribution to the participant pursuant to the
        Distribution Instructions. We will not review or verify the accuracy of
        the Distribution Instructions or Participant Information provided by you
        and have no responsibility to ascertain whether any Distribution
        Instructions received by us are in compliance with (i) the terms of the
        Plan, (ii) any directions given to Plan or Participant Elections made by
        or for a participant, or (iii) applicable Laws;
      </p>
      <p>
        (b) Withhold federal and mandatory state tax withholding, if any, from
        the Distribution and forward to the IRS and applicable states, pursuant
        to the Distribution Instructions;
      </p>
      <p>
        (c) File IRS forms 1099-R and any required federal and state tax forms
        using our TIN, pursuant to the Distribution Instructions;
      </p>
      <p>
        (d) Make available to Plan copies of Form 1099-R relating to the
        Distribution, upon request;
      </p>
      <p>
        (e) Deduct Custodian’s fees for the Benefit Distribution Services and
        other fees from the Distribution, if applicable, as directed in the
        Distribution Instructions. Any authorized agent fee deducted from a
        Distribution pursuant to Distribution Instructions will be remitted to
        the authorized agent within 60 days of fee deduction from the
        Distribution.
      </p>
      <p>
        (f) In the event of a Failed Distribution, we will advise you. If we do
        not receive timely updated Distribution Instructions from you or are not
        able to complete a Distribution pursuant to updated Distribution
        Instructions, we will (i) send the Notice and (ii) open the IRA for the
        benefit of the Plan participant to receive Plan funds representing the
        Failed Distribution, if applicable, as directed by you in Section 3
        above. We have no obligation to ascertain any facts underlying a Failed
        Distribution or to attempt to update or search for a Failed Distribution
        recipient participant prior to establishing the IRA.
      </p>
      <p>
        We will not be liable for any action taken by us in good faith and in
        accordance with any initial or updated Distribution Instructions or
        other direction from Plan or its authorized agent or for any tax
        withholding and reporting, if any, we are unable to correct.
      </p>
      <p>
        We will not store or maintain the electronic data file(s) or associated
        Distribution Instructions on your behalf once Benefit Distribution
        Services are completed and any data file(s) or Distribution Instructions
        submitted to us related to a specific Distribution may be deleted 240
        days after the relevant Distribution is completed.
      </p>
      <p>
        Data cannot be recovered once deleted. You acknowledge that you are
        solely responsible for the maintenance of Distribution Instructions,
        Participant Elections, and other relevant distribution records, on
        behalf of the Plan and we shall not be liable for any loss or
        destruction of Distribution Instructions as provided herein. Each party
        is solely responsible for its own compliance obligations.
      </p>
      <p>
        We retain the right to reject any Distribution by returning the Plan
        funds earmarked for the applicable Distribution to Plan or its
        authorized agent (“Rejected Distribution”). Plan or its authorized agent
        has provided delivery instructions for the return of Plan funds
        representing a Rejected Distribution, which are attached to this
        Addendum. We are not liable for any Plan funds returned to Plan.
      </p>
      <p>
        Except as specifically set forth in this Section 4, we have no other
        obligations with respect to Benefit Distribution Services.
      </p>
      <p>
        <b>5. Scope of Services. </b>
        <br />
        You agree that we are performing only ministerial services at your
        direction on behalf of the Plan pursuant to this Addendum. You represent
        that the ministerial services to be rendered by us under this Addendum
        are consistent with the procedures and terms of the Plan, applicable
        directions given to Plan or Participant Elections made by or for a
        participant, and all applicable Laws. Accordingly, you further agree
        that we are not intended to be a fiduciary, nor is it intended that we
        have the requisite authority or control, to perform any services or
        functions which could cause us to become a fiduciary of any Plan.
        Furthermore, you agree that we shall not be obligated to perform any
        services or to take any actions which, in our reasonable judgment, would
        cause us to be treated as a fiduciary under any applicable Laws, nor
        shall you request that we perform such actions.
      </p>
      <p>
        You specifically acknowledge that we may use third-party services
        vendors to provide Benefit Distribution Services or contact third party
        retirement benefit service providers to complete Distributions, and by
        using the services, you agree and consent to the sharing of Distribution
        Instructions with such vendors and service providers in order to enable
        activities related to the provision of Benefit Distribution Services.
      </p>
      <p>
        We expressly disclaim that the Benefit Distribution Services are, and
        will not be deemed to be regulatory, compliance, legal, retirement
        fund/plan, ERISA or tax advice, counsel or opinion.
      </p>
      <p>
        <b>Plan Custodial Account (PCA).</b> Plan funds that you transfer to us
        for Distributions will be held by us in a PCA on behalf of the Plan
        pending distribution and we will provide custodial services for each PCA
        pursuant to the terms of this Addendum. Our sole responsibility with
        respect to Plan funds held in the PCA will be to: (i) take all actions
        necessary for the safekeeping of Plan funds pending distribution; (ii)
        process each Distribution pursuant to Distribution Instructions; (iii)
        process each Failed Distribution pursuant to updated Distribution
        Instructions, and the Automatic Rollover Services Agreement and this
        Addendum; (iv) transfer Plan funds as Plan or its authorized agent
        directs, provided sufficient Plan funds are available for such
        distribution or transfer; or (iv) return to Plan or its authorized
        agent, Plan funds for any Rejected or Failed Distribution (if directed).
      </p>
      <p>
        You will provide all Plan information requested by us and available to
        you, required to establish the PCA (“Plan Information”), including
        without limitation, all information required under the United States
        Patriot Act. We may request personal information from a Plan
        representative in order to establish the PCA. We may hold title to Plan
        funds or any part thereof in {COMPANY_NAME.full}, as custodian, in
        individual or omnibus accounts. We will only be responsible for Plan
        funds delivered to us.
      </p>
      <p>
        You direct us to place Plan funds held in the PCA in a demand deposit
        account at one or more unaffiliated FDIC insured banks selected by us.
        We may retain interest, if any, paid by such unaﬃliated banks on the
        average cash balance attributable to the PCA as compensation for
        servicing and administering the PCA and rendering other services in
        connection with custody of the PCA.
      </p>
      <p>
        The parties expressly agree that Custodian does not assume any
        obligation to review at any time the Plan funds held in the PCA or to
        provide any recommendation, legal, investment, or tax advice to Plan or
        its authorized agent in connection with any Distribution or transfer of
        Plan funds to us.
      </p>
      <p>
        We will not maintain Plan funds in the PCA for Failed Distributions or
        Rejected Distributions, which remain in the PCA after attempted
        distribution pursuant to Distribution Instructions, the Agreement, or
        return to Plan or its authorized agent (‘PCA Funds”). Plan or its
        authorized agent will direct us regarding the distribution of PCA Funds.
        If we do not receive timely direction from Plan or its authorized agent
        regarding distribution of PCA Funds, you direct us to escheat the PCA
        Funds attributable to a participant Distribution remaining in the PCA in
        accordance with our procedure in effect at the time, upon prior notice
        to Plan, or as otherwise required by applicable Law.
      </p>
      <p>
        We will provide a statement of the PCA to Plan, upon request. Plan may
        file written objections to a PCA statement, if any, within 45 days of
        statement date, after which such statement is deemed approved and will
        be a full discharge of us regarding the transactions and information
        disclosed on such statement.
      </p>
      <p>
        <b>7. Fees</b> You agree to pay us fees for Benefit Distribution
        Services pursuant to the Fee Schedule attached as Exhibit A hereto. Fees
        will be invoiced or, at our option, pre-paid online via credit card at
        the time services are ordered, unless directed otherwise in the
        Distribution Instructions. Invoices are due no later than 30 days after
        the date of the invoice. We may amend the service fees from time to time
        by providing notice to you. You agree that your continued use of all or
        part of the Benefit Distribution Services will be subject to your
        payment of any applicable fees.
      </p>
      <p>
        We will also be entitled to reimbursement for all expenses and fees
        incurred in connection with the PCA, if any.
      </p>
      <p>
        In the event that any undisputed invoice remains unpaid 30 days after
        the invoice date, you will pay interest on the past due amounts at a
        rate which is the lesser of one and a half percent (1.5%) per month, or
        the highest rate permitted by applicable law, until payment in full is
        made. In the event we are required to take any action to collect overdue
        payments from you, including but not limited to engaging the services of
        a collection agency or initiating legal proceedings, you will be
        responsible for reimbursing us for all reasonable costs and expenses
        incurred in connection with such collection efforts, including attorney
        fees, court costs, and any other expenses.
      </p>
      <p>
        We reserve the right to suspend provision of Benefit Distribution
        Services to you if any invoice remains unpaid for a period exceeding
        sixty (60) days from the due date, until such time as the overdue
        amounts, including any applicable late payment penalties, interest, and
        collection costs, are paid in full.
      </p>
      <p>
        <b>8. Representations and Warranties.</b>
        <br />
        (a) Plan represents and warrants:
        <br />
        &nbsp;&nbsp;(i) This Addendum has been duly authorized, executed and
        delivered by Plan and constitutes a valid and binding agreement of the
        Plan.
        <br />
        &nbsp;&nbsp;(ii) Any Distribution directed and Distribution Instructions
        provided by Plan or its authorized agent are in accordance with the
        terms of the Plan, the directions given to Plan or Participant Elections
        made by or for a participant, and applicable Laws, including without
        limitation, the Safe Harbor requirements for notifications to
        participants for distributions from an active and /or terminating Plan
        as described in Title 29 of the Code of Federal Regulations Sections
        2550.404a-2 and 404a-3 and Sections 401(a)(31)(B) and 402(f) of the Code
        as applicable, and any successor provisions or additional regulatory
        guidance or Laws that may govern your responsibilities with respect to
        Distributions from the Plan.
        <br />
        &nbsp;&nbsp;(iii) Plan has taken all steps necessary to allow us to
        process Distributions pursuant to this Addendum.
        <br />
        &nbsp;&nbsp;(iv) The Distribution Instructions, Participant Information
        and Plan Information provided to us pursuant to this Addendum contain
        the most recent and accurate information available to Plan.
        <br />
        &nbsp;&nbsp;(v) Plan has the authority to issue Distribution
        Instructions deducting fees due Custodian and/or fees due Plan’s
        authorized agent and any other fees, as applicable, from the
        participants’ Distributions, such deduction of applicable fees has been
        disclosed to applicable participants by Plan and any deductions from
        Distributions pursuant to Distribution Instructions are in accordance
        with the terms of the Plan and applicable Laws.
        <br />
        &nbsp;&nbsp;(vi) Plan has independently concluded that the arrangement
        for services described in this Addendum satisfies the terms of the Plan
        and applicable Law, and has not relied on us and we have not provided
        any recommendation, investment, legal or tax advice to Plan in
        connection with the Benefit Distribution Services.
        <br />
      </p>
      <p>
        (b) Custodian represents and warrants that Custodian has experience
        necessary to process Distributions pursuant to Distribution Instructions
        provided by Plan and provide 1099R and any required federal and state
        tax reporting and withholding services for Distributions processed
        pursuant to this Addendum.
        <br />
      </p>

      <p>
        <b>
          9. Indemnification and Limitation of Liability for Distributions.{' '}
        </b>
      </p>
      <p>
        (a) You will indemnify and hold us harmless from any and all Damages
        arising from or claimed to have arisen from (i) your breach of this
        Addendum, including any representation or warranty made by you in this
        Addendum or the Agreement, except Damages arising from our gross
        negligence, bad faith or willful misconduct; (ii) your gross negligence,
        bad faith or willful misconduct; (iii) your violation of the terms of
        the Plan, ERISA, the Code or applicable Laws; (iv) inaccurate
        Participant Information, Distribution Instructions or Plan Information
        provided by Plan or its authorized agent; (v) any act or omission by us
        arising out of or resulting from our reliance upon the Participant
        Information, Plan Information, Distribution Instructions or any other
        directions or information provided by Plan or its authorized agent; and
        (vi) any acts or omissions of Plan or its authorized agents.
        <br />
        (b) We will indemnify and hold you harmless from any and all Damages
        arising from or claimed to have arisen from (i) our breach of this
        Addendum, including any representation or warranty made by us in this
        Addendum, except Damages arising from your gross negligence, bad faith
        or willful misconduct; or (ii) our gross negligence, bad faith or
        willful misconduct
        <br />
        Except for our indemnification obligations, our liability for Damages
        hereunder shall not exceed $100,000. Each party shall have a duty to
        mitigate Damages for which the other party is responsible. In no event
        will either party be liable for any incidental, consequential, indirect
        or special damages of any nature whatsoever. The terms of these
        limitations of liability shall survive the termination of this Addendum.
      </p>
      <p>
        <b>10. Term.</b> This Addendum may be terminated by either party at any
        time upon sixty (60) days’ written notice or termination of the
        underlying Agreement. Termination will not affect any Distribution
        previously completed or in process prior to termination. If the Addendum
        is terminated, we will deliver any PCA Funds as soon as practicable to
        Plan or at Plan’s written direction, to a third party pursuant to
        delivery instructions received from Plan or its authorized agent. If we
        have not received delivery instructions prior to the expiration of the
        60-day notice period, we will return all PCA Funds, if any, to Plan at
        the last address or delivery instructions on file with us. This Addendum
        may also be terminated immediately by us if we determine you have
        materially breached this Addendum, including any representation or
        warranty made by you, or as otherwise required by applicable Law, in our
        sole discretion.
      </p>
      <p>
        IN WITNESS WHEREOF, the parties hereto have caused this Addendum to be
        executed as of the date of the Plan Fiduciary’s acceptance set forth
        below.
      </p>
    </div>
  )
}

export default BDAddendumLanguageOfAgreement
