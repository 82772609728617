const NAME_PREFIX_ABBREVIATIONS = ['Mr', 'Ms', 'Mrs']
const NAME_PREFIXES = ['Mister', 'Miss', 'Missus']

// names cannot begin with a prefix (or be a prefix) but names like Mrittika, Msrah should pass the validation
const namePrefixAbbreviationRegex = NAME_PREFIX_ABBREVIATIONS.map(
  (prefix) => `${prefix}([ .,]|$)`
).join('|')

const namePrefixRegex = NAME_PREFIXES.map((prefix) => `${prefix}([ ]|$)`).join(
  '|'
)

function createNamePrefixValidator() {
  return {
    validate: 'regex_excludes',
    regex: `^(${namePrefixAbbreviationRegex}|${namePrefixRegex})`,
    regexFlags: { ignoreCase: true },
    error: 'Do not include name prefixes',
  }
}

export default createNamePrefixValidator
