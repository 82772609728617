import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon as ResetIcon } from 'images/utility-icons/reset.svg'
import { Icon as DocumentIcon } from 'images/document-new.svg'
import { ClickableTooltip } from 'components'

const propTypes = {
  handleUpload: PropTypes.func,
  numberOfParticipants: PropTypes.number,
}

const defaultProps = {
  handleUpload: null,
  numberOfParticipants: 0,
}

function ParticipantsSummaryContainer({ handleUpload, numberOfParticipants }) {
  return (
    <div className="file-name-container">
      <div className="document">
        <div className="document-icon-wrapper">
          <DocumentIcon aria-hidden="true" />
        </div>
        {numberOfParticipants === 1 ? (
          <p>You've submitted {numberOfParticipants} participant</p>
        ) : (
          <p>You've submitted {numberOfParticipants} participants</p>
        )}
      </div>
      {handleUpload && (
        <ClickableTooltip
          content="Remove"
          onClick={handleUpload}
          className="action-button"
          ariaLabel="Remove"
        >
          <ResetIcon aria-hidden="true" />
        </ClickableTooltip>
      )}
    </div>
  )
}

ParticipantsSummaryContainer.propTypes = exact(propTypes)
ParticipantsSummaryContainer.defaultProps = defaultProps

export default React.memo(ParticipantsSummaryContainer)
