export const LABELS = {
  // Below are the common labels columns
  RECORDKEEPER: 'Recordkeeper',
  THIRD_PARTY_ADMINISTRATOR: 'Third party administrator',
  PLAN_SPONSOR: 'Plan sponsor',
  COMPLETE_LEGAL_PLAN_NAME: 'Complete legal plan name',
  EMPLOYER_IDENTIFICATION_NUMBER: 'Employer Identification Number (EIN)',
  PLAN_EMPLOYER_IDENTIFICATION_NUMBER:
    'Plan Employer Identification Number (EIN)',
  THREE_DIGIT_PLAN_NUMBER: 'Three-digit plan number (PN)',
  PROVIDER_PLAN_ID_NUMBER: 'Provider plan ID number',
  FIRST_NAME: 'First name',
  MIDDLE_NAME: 'Middle name',
  LAST_NAME: 'Last name',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'ZIP code',
  COUNTRY_CODE: 'Country code',
  DATE_OF_BIRTH: 'Date of birth',
  SSN: 'SSN',
  EMAIL_ADDRESS: 'Email address',
  TRADITIONAL_IRA_PRE_TAX_AMOUNT: 'Traditional IRA pre-tax amount',
  TRADITIONAL_IRA_AFTER_TAX_AMOUNT: 'Traditional IRA after-tax amount',
  ROTH_IRA_AMOUNT: 'Roth IRA amount',
  PLAN_FIDUCIARY_NAME: 'Plan fiduciary (entity name)',
  PARTICIPANT_SSN: 'Participant SSN',
  PARTICIPANT_FIRST_NAME: 'Participant first name',
  PARTICIPANT_MIDDLE_NAME: 'Participant middle name',
  PARTICIPANT_LAST_NAME: 'Participant last name',
  PARTICIPANT_ADDRESS_1: 'Participant address line 1',
  PARTICIPANT_ADDRESS_2: 'Participant address line 2',
  PARTICIPANT_ADDRESS_3: 'Participant address line 3',
  PARTICIPANT_CITY: 'Participant city',
  PARTICIPANT_STATE: 'Participant state',
  PARTICIPANT_ZIP: 'Participant ZIP code',
  PARTICIPANT_DOB: 'Participant DOB',
  PARTICIPANT_DOD: 'Participant DOD',
  PHONE_NUMBER: 'Phone number',
  MISCELLANEOUS_1: 'Miscellaneous 1',
  MISCELLANEOUS_2: 'Miscellaneous 2',

  //Below are the feilds specific to BD flatfile
  TRANSACTION_TYPE_FOR_BD:
    'Transaction type (check, wire, ACH, check/overnight delivery)',
  PAYMENT_INSTRUCTIONS_FOR_BD:
    'Payment instructions (direct to participant, direct rollover)',
  AMOUNT: 'Total distribution amount',
  TPA_BD_PROCESSING_FEE: 'Third party fees',
  INSPIRA_BD_PROCESSING_FEE: 'Inspira fees',
  GROSS_DISTRIBUTION_AMOUNT: 'Gross distribution amount',
  WORK_FOR: 'Work for',
  REASON: 'Reason',
  WIRE_ACH_1: 'Wire/ACH receiving bank',
  ABA_ROUTING: 'ABA routing number',
  WIRE_ACH_2: 'Wire/ACH receiving account name',
  WIRE_ACH_3: 'Wire/ACH receiving account number',
  FFC_NAME: 'FFC name',
  FFC_NUMBER: 'FFC number',
  ACCOUNT_TYPE: 'Account type (checking or savings)',
  CHECK_PAYABLE_INFO: 'Check payable information (for check)',
  ACCOUNT_NUMBER: 'Plan number/account number (for check)',
  ADDRESS_FOR_CHECK: 'Address line 1 ( for check)',
  CITY_FOR_CHECK: 'City (for check)',
  STATE_FOR_CHECK: 'State (for check)',
  ZIP_FOR_CHECK: 'ZIP code (for check)',
  GROSS_DIST: 'Gross distribution (Box 1)',
  TAXABLE_AMOUNT: 'Taxable amount (Box 2a)',
  TAXABLE_AMOUNT_NOT_DET: 'Taxable amount not determined (Box 2b/Null or "X")',
  TOTAL_DISTRIBUTION: 'Total distribution (Box 2b/Null or "X")',
  CAPITAL_GAIN: 'Capital gain (Box 3)',
  FEDERAL_INCOME_TAX_W: 'Federal income tax withheld (Box 4) - percent (%)',
  FEDERAL_INCOME_TAX_W_AMOUNT: 'Federal income tax withheld (Box 4) - ($)',
  EMPLOYEE_CONTRIBUTIONS:
    'Employee contributions/designated Roth contributions or insurance premiums (Box 5)',
  NET_APPRECIATION:
    "Net unrealized appreciation in employer's securities (Box 6)",
  DISTRIBUTION_CODES: 'Distribution code(s) (Box 7)',
  PERCENTAGE_TOTAL_DIST: 'Your percentage of total distribution (Box 9a)',
  TOTAL_EMPLOYEE_CONTRIBUTIONS: 'Total employee contributions (Box 9b)',
  AMOUNT_ALLOCABLE: 'Amount allocable to IRR within 5 years (Box 10)',
  FIRST_YEAR_OF_DESIG: '1st year of desig. Roth contrib. (Box 11)',
  STATE_TAX_W:
    'State tax withheld (Box 14) - percent (%) (Note: mandatory states only)',
  STATE_TAX_W_AMOUNT:
    'State tax withheld (Box 14) - ($) (Note: mandatory states only)',
  PAYER_STATE_NUMBER:
    "State/payer's state no. (Box 15) (Note: mandatory states only)",

  // Below are the fields specific to UncashedChecks
  TRADITIONAL_IRA_AMOUNT: 'Traditional IRA amount',
  DATE_ORIGIONAL_DISTRIBUTION: 'Date of original distribution',
  TAX_WITHHELD: 'Was tax withheld? Y/N',
}
