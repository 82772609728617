import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { TableColumn } from 'lp-components'
import {
  StatusBadge,
  Tooltip,
  PaginatedAndSortableTable,
  Filter,
  TableHeading,
  DownloadTableButton,
} from 'components'
import { SearchOrdersFilterDialog } from '../components'
import { formatDate, tippyPlugins, parseDateFromString } from 'utils'
import { SearchOrderStatus } from 'config/search-services'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  SearchOrderTooltip,
} from 'config/notifications'
import { isEqual, filter, includes, noop } from 'lodash'
import { isBefore, isAfter, isSameDay } from 'date-fns'
import { Link } from 'react-router-dom'
import * as Types from 'types'
import { downloadSearchOrderResults } from 'api'
import { Icon as DownloadIcon } from 'images/download.svg'
import { Icon as NewIndicator } from 'images/status-icons/new-indicator.svg'
import { flashErrorMessage } from 'redux-flash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors } from '../reducer'

const INITIAL_FILTERS = {
  status: [],
  searchType: [],
  startSubmittedDate: '',
  endSubmittedDate: '',
}

const propTypes = {
  noRowsFoundMessage: PropTypes.node,
  tableId: PropTypes.string.isRequired,
  searches: PropTypes.array.isRequired,
  searchServices: PropTypes.arrayOf(Types.searchService).isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  updateSearchOrderDownloadDate: PropTypes.func.isRequired,
  setShouldTriggerSearchesFetch: PropTypes.func.isRequired,
  shouldTriggerSearchesFetch: PropTypes.bool.isRequired,
  fieldMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasSearchOrders: PropTypes.bool.isRequired,
}

const { hideOnEsc, hideOnPopperBlur, preventEventBubbling } = tippyPlugins

const defaultProps = {
  noRowsFoundMessage: null,
  planFiduciaries: null,
}

function SearchOrderLink({ data: { searchOrderID, orderNumber } }) {
  return (
    <td>
      <Link
        to={(location) => {
          return {
            pathname: `/products/search/${searchOrderID}`,
            state: { search: location.search },
          }
        }}
        className="link-text"
      >
        {orderNumber}
      </Link>
    </td>
  )
}

function DateCell(props) {
  const valList = props.value.split(' at')

  return (
    <td>
      <span className="is-nowrap">{valList[0]} </span>
      <span className="is-nowrap">at {valList[1]}</span>
    </td>
  )
}

function HeaderCell({ column: { label }, onClick }) {
  return (
    <th onClick={onClick}>
      <div className="is-minwidth">{label}</div>
    </th>
  )
}

function SearchOrdersTable({
  searches,
  tableId,
  noRowsFoundMessage,
  searchServices,
  flashErrorMessageHandler,
  updateSearchOrderDownloadDate,
  setShouldTriggerSearchesFetch,
  shouldTriggerSearchesFetch,
  fieldMappings,
  hasSearchOrders,
}) {
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const searchTypeMap = useMemo(() => {
    return searchServices.reduce((map, service) => {
      map[service.searchService] = service.turnaroundTime
      return map
    }, {})
  })
  const getTurnaroundTime = useCallback(
    (searchTypeID) => {
      return searchTypeMap[searchTypeID] || ''
    },
    [searchTypeMap]
  )

  const filteredSearches = useMemo(() => {
    if (!searches) return

    const updatedSearches = searches.map((search) => {
      const turnaroundTime = getTurnaroundTime(search.searchTypeID)
      return { ...search, turnaroundTime }
    })
    const filteredSearchResults = filter(updatedSearches, (searchOrder) => {
      const submittedOn = parseDateFromString(searchOrder.submittedOn)
      return (
        (filters.status.length
          ? includes(filters.status, searchOrder.status)
          : true) &&
        (filters.searchType.length
          ? includes(filters.searchType, searchOrder.searchTypeID)
          : true) &&
        (filters.startSubmittedDate
          ? isAfter(submittedOn, filters.startSubmittedDate) ||
            isSameDay(submittedOn, filters.startSubmittedDate)
          : true) &&
        (filters.endSubmittedDate
          ? isBefore(submittedOn, filters.endSubmittedDate) ||
            isSameDay(submittedOn, filters.endSubmittedDate)
          : true)
      )
    })
    return filteredSearchResults
  }, [filters, searches, getTurnaroundTime])
  const hasFiltersApplied = !isEqual(filters, INITIAL_FILTERS)

  function useSearchOrderDownload(data) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const handleDownload = async () => {
      if (!shouldTriggerSearchesFetch) {
        try {
          setIsLoading(true)
          await Promise.all([
            updateSearchOrderDownloadDate(data.orderNumber),
            downloadSearchOrderResults(data.searchOrderID, data.orderNumber),
          ])
        } catch (e) {
          setError(e.message)
        } finally {
          setIsLoading(false)
          setShouldTriggerSearchesFetch(true)
        }
      }
    }
    return { download: data ? handleDownload : noop, isLoading, error }
  }

  function DownloadButton({ data: { orderNumber, searchOrderID, status } }) {
    const {
      download,
      isLoading: isDownloading,
      error: downloadError,
    } = useSearchOrderDownload({ orderNumber, searchOrderID })

    useEffect(() => {
      if (downloadError) {
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
      }
    }, [downloadError, flashErrorMessageHandler])

    return (
      <>
        {status === SearchOrderStatus.RESULTS_AVAILABLE && (
          <button
            type="button"
            onClick={isDownloading ? noop : download}
            className="button-text search-order-download-button"
          >
            <DownloadIcon aria-hidden="true" />
            Download
          </button>
        )}
      </>
    )
  }

  function StatusCell({ data: { ...values } }) {
    const { status, newIndicator, orderNumber, searchOrderID } = values
    const resultsAvailable = status === SearchOrderStatus.RESULTS_AVAILABLE
    return (
      <td>
        <div className="status-container-element">
          {resultsAvailable && !newIndicator && (
            <NewIndicator aria-hidden="true" />
          )}
          <Tooltip
            content={SearchOrderTooltip[status]}
            plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
          >
            <StatusBadge status={status} />
          </Tooltip>
        </div>
        {resultsAvailable && (
          <div className="status-container-element">
            <DownloadButton data={{ orderNumber, searchOrderID, status }} />
          </div>
        )}
      </td>
    )
  }
  const disabled = !filteredSearches?.length
  const showIcon = disabled

  return (
    <>
      <TableHeading title="My searches">
        {hasSearchOrders && (
          <DownloadTableButton
            data={filteredSearches}
            fieldMappings={fieldMappings}
            fileName="Inspira Financial_Retirement Services_Search Orders"
            disabled={disabled}
            showIcon={showIcon}
          />
        )}
        <div className="search-filter-container">
          <Filter
            toggleMenu={() => setShowFilterModal((s) => !s)}
            isActive={hasFiltersApplied}
          />
          {showFilterModal && (
            <SearchOrdersFilterDialog
              filters={filters}
              setFilters={setFilters}
              setShowFilterModal={setShowFilterModal}
            />
          )}
        </div>
      </TableHeading>
      <PaginatedAndSortableTable
        data={filteredSearches}
        initialColumn="submittedOn"
        initialAscending={false}
        className="search-table"
        tableId={tableId}
        noRowsFoundMessage={noRowsFoundMessage}
      >
        <TableColumn
          name="orderNumber"
          label="Order number"
          component={SearchOrderLink}
          headerComponent={HeaderCell}
          disabled
        />
        <TableColumn name="status" component={StatusCell} disabled />
        <TableColumn name="searchTypeID" label="Search type" disabled />
        <TableColumn
          name="submittedOn"
          label="Submitted on"
          component={DateCell}
          format={(val) => formatDate(val)}
        />
        <TableColumn
          name="turnaroundTime"
          label="Estimated turnaround"
          headerComponent={HeaderCell}
          disabled
        />
      </PaginatedAndSortableTable>
    </>
  )
}

SearchOrdersTable.propTypes = exact(propTypes)
SearchOrdersTable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    shouldTriggerSearchesFetch: selectors.shouldTriggerSearchesFetch(state),
  }
}
const mapDispatchToProps = {
  flashErrorMessageHandler: flashErrorMessage,
  updateSearchOrderDownloadDate: apiActions.updateSearchOrderDownloadDate,
  setShouldTriggerSearchesFetch: actions.setShouldTriggerSearchesFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(SearchOrdersTable)
)
