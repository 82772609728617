import {
  ActivePlansSubmissionMethodDB,
  ActivePlansSubmissionMethodDC,
  SubmissionMethod,
  ActiveBenefitsPlanType,
} from '../config/automatic-rollovers'

function getRemoveFromSubmissionOption(type) {
  if (type === ActiveBenefitsPlanType.DEFINED_BENEFIT) {
    return {
      key: ActivePlansSubmissionMethodDB.REMOVE_FROM_SUBMISSION,
      value: 'removeFromSubmission',
    }
  }
  if (type === ActiveBenefitsPlanType.DEFINED_CONTRIBUTION) {
    return {
      key: ActivePlansSubmissionMethodDC.REMOVE_FROM_SUBMISSION,
      value: 'removeFromSubmission',
    }
  }
  return {
    key: SubmissionMethod.REMOVE_FROM_SUBMISSION,
    value: 'removeFromSubmission',
  }
}

export default getRemoveFromSubmissionOption
