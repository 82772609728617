// The new Fields for the BD flatfile
export const BD_TRANSANCTION_INFO = {
  TRANSACTION_TYPE: 'transactionType',
  PAYMENT_INSTRUCTIONS: 'paymentInstructions',
  AMOUNT: 'amount',
  TPA_BD_PROCESSING_FEE: 'tpaBenefitDistributionProcessingFee',
  INSPIRA_BD_PROCESSING_FEE: 'inspiraBenefitDisbursementProcessingFee',
  GROSS_DISTRIBUTION_AMOUNT: 'grossDistributionAmount',
  WIRE_ACH_1: 'wireAch',
  ABA_ROUTING: 'abaRouting',
  WIRE_ACH_2: 'wireAch2',
  WIRE_ACH_3: 'wireAch3',
  FFC_NAME: 'ffcName',
  FFC_NUMBER: 'ffcNumber',
  ACCOUNT_TYPE: 'accountType',
  CHECK_PAYABLE_INFO: 'checkPayableInfo',
  ACCOUNT_NUMBER: 'accountNumber',
  ADDRESS_FOR_CHECK: 'addressForCheck',
  CITY_FOR_CHECK: 'cityForCheck',
  STATE_FOR_CHECK: 'stateForCheck',
  ZIP_FOR_CHECK: 'zipForCheck',
  GROSS_DIST: 'grossDistribution',
  TAXABLE_AMOUNT: 'taxableAmount',
  TAXABLE_AMOUNT_NOT_DET: 'taxableAmountNotDetermined',
  TOTAL_DISTRIBUTION: 'totalDistribution',
  CAPITAL_GAIN: 'capitalGain',
  FEDERAL_INCOME_TAX_W: 'federalIncomeTax',
  FEDERAL_INCOME_TAX_W_AMOUNT: 'federalIncomeTaxAmount',
  EMPLOYEE_CONTRIBUTIONS: 'employeeContributions',
  NET_APPRECIATION: 'netAppreciation',
  DISTRIBUTION_CODES: 'distributionCode',
  PERCENTAGE_TOTAL_DIST: 'percentageTotalDistribution',
  TOTAL_EMPLOYEE_CONTRIBUTIONS: 'totalEmployeeContributions',
  AMOUNT_ALLOCABLE: 'amountAllocable',
  FIRST_YEAR_OF_DESIG: 'firstYearContrib',
  STATE_TAX_W: 'stateTaxWithheld',
  STATE_TAX_W_AMOUNT: 'stateTaxWithheldAmount',
  PAYER_STATE_NUMBER: 'payerStateNumber',
}
