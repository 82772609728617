import { groupBy, map } from 'lodash'
import { convertNumber } from 'utils'
import { BD_TRANSANCTION_INFO } from '../config/bdTransactionInfoKeys'

// Summarize an array of participant records by plan (used in ARO participant file flow)
// The summary captures the following per plan: 1) total number of participants and 2) total rollover amount per account:
// a) traditional IRA pre-tax, b) traditional IRA post-tax, c) roth IRA
function summarizeParticipantsDataByPlanBD(participantsData) {
  const participantsHash = groupBy(
    participantsData,
    (participant) => participant.mappedPlanName || participant.planName
  )

  return map(participantsHash, (records, planName) => {
    return {
      planName,
      participants: records.length,
      [BD_TRANSANCTION_INFO.AMOUNT]: sumUpRecordsAccount(
        records,
        BD_TRANSANCTION_INFO.AMOUNT
      ),
      [BD_TRANSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE]: sumUpRecordsAccount(
        records,
        BD_TRANSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE
      ),
      [BD_TRANSANCTION_INFO.TPA_BD_PROCESSING_FEE]: sumUpRecordsAccount(
        records,
        BD_TRANSANCTION_INFO.TPA_BD_PROCESSING_FEE
      ),
      [BD_TRANSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT]: sumUpRecordsAccount(
        records,
        BD_TRANSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT
      ),
    }
  })
}

// ----- PRIVATE -----

const sumUpRecordsAccount = (records, accountType) => {
  return records.reduce(
    (total, record) => total + convertNumber(record[accountType]),
    0
  )
}

export default summarizeParticipantsDataByPlanBD
