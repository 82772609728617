import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Table, TableColumn } from 'lp-components'
import { formatCurrency } from 'utils'
import { IRA_AMOUNT } from '../../../config/iraAmount'
import { RolloverType } from 'config/automatic-rollovers'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.node.isRequired,
  rolloverType: PropTypes.string.isRequired,
}

const defaultProps = {}

function ParticipantsSummaryTable({ data, title, rolloverType }) {
  return (
    <div className="participant-summary-table">
      <div className="form-header">{title}</div>
      <Table data={data}>
        <TableColumn name="planName" label="Complete legal plan name" />
        <TableColumn name="participants" label="Number of participants" />
        <TableColumn
          name={IRA_AMOUNT.TRADITIONAL_PRE_TAX}
          label={
            rolloverType === RolloverType.STANDARD
              ? 'Traditional IRA pre-tax amount'
              : 'Traditional IRA amount'
          }
          format={formatCurrency}
        />
        {rolloverType === RolloverType.STANDARD && (
          <TableColumn
            name={IRA_AMOUNT.TRADITIONAL_AFTER_TAX}
            label="Traditional IRA after-tax amount"
            format={formatCurrency}
          />
        )}
        <TableColumn
          name={IRA_AMOUNT.ROTH}
          label="Roth IRA amount"
          format={formatCurrency}
        />
      </Table>
    </div>
  )
}

ParticipantsSummaryTable.propTypes = exact(propTypes)
ParticipantsSummaryTable.defaultProps = defaultProps

export default React.memo(ParticipantsSummaryTable)
