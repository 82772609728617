const NOT_APPLICABLE_VARIATIONS = ['Not Applicable', 'N/A', 'NA', 'N\\.A\\.']
const notApplicableRegex = NOT_APPLICABLE_VARIATIONS.map(
  (notApplicable) => `(^${notApplicable}$)`
).join('|')

function createNotApplicableValidator() {
  return {
    validate: 'regex_excludes',
    regex: notApplicableRegex,
    regexFlags: { ignoreCase: true },
    error: 'Required',
  }
}

export default createNotApplicableValidator
