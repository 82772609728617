import { downloadCsv } from 'utils'
import { getFlatfileFields } from 'flatfile-config'

function downloadDiscardedParticipants({
  participants,
  originalFileName,
  recordType,
}) {
  const FlatfileFields = getFlatfileFields(recordType)
  const fieldKeys = FlatfileFields.fields.map((field) => field.key)
  const fieldLabels = FlatfileFields.fields.map((field) => field.label)

  const participantRows = convertObjectsToArrays(participants, [
    ...fieldKeys,
    'reasonForDiscard',
  ])
  const headerRow = [...fieldLabels, 'Reason for Discard']
  const csvData = [headerRow, ...participantRows]
  const fileName = `${getFileBaseName(
    originalFileName
  )}-discarded-participants.csv`

  downloadCsv(csvData, { fileName })
}

// ----- PRIVATE -----

const getFileBaseName = (fileName) => {
  const extensionIndex = fileName.lastIndexOf('.')
  if (extensionIndex === -1) return fileName
  return fileName.slice(0, extensionIndex)
}

// Convert an array of objects into an array of arrays consisting of only values, in the same order as the provided fields
// e.g., [ {firstName: 'John', middleName: 'Lee', lastName: 'Doe'} ] -> [ ['John', 'Lee', 'Doe'] ]
const convertObjectsToArrays = (objects, keys) => {
  return objects.map((object) => keys.map((key) => object[key]))
}

export default downloadDiscardedParticipants
