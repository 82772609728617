function validatePlanNumber() {
  return {
    invalidCharacters: {
      regex: '^(\\D)',
      error: 'Invalid characters used',
    },
    mustBeThreeDigits: {
      regex: '^(\\d{3})$',
      error: 'Must be three digits',
    },
  }
}

export default validatePlanNumber
