import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors as globalSelectors } from './reducer'
import { Modal } from 'lp-components'
import {
  Header,
  SkipNavLink,
  Footer,
  FlashMessageContainer,
  Notification,
} from 'components'
import * as globalActions from './actions'
import { Link } from 'react-router-dom'
import { Variant as NotificationVariant } from 'components/Notification'
// The following import is needed to get the GetFeedback script used for the implementation of GetFeedback
// This import might be removed in the future if the new implementation doesnot require it
// import { GET_FEEDBACK_SCRIPT, isGetFeedbackEnabled } from 'config/portal'
// import { Helmet } from 'react-helmet'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  hideSuccessModal: PropTypes.func.isRequired,
  successModalMessage: PropTypes.object,
  currentUser: PropTypes.object,
}

const defaultProps = {
  successModalMessage: null,
  currentUser: null,
}

function FullLayout({
  children,
  flashMessages,
  hideSuccessModal,
  successModalMessage,
  currentUser,
}) {
  const hasCustomActions = successModalMessage?.actions?.length > 0
  const removeHtmlTags = (html) => {
    return html.replace(/<[^>]*>/g, '')
  }

  const extractNotification = (html) => {
    const matches = html.match(/<p>(.*?)<\/p><p>(.*?)<\/p>/)
    if (matches) {
      const header = removeHtmlTags(matches[1])
      const body = removeHtmlTags(matches[2])
      return {
        header,
        body,
      }
    } else {
      return {
        header: '',
        body: removeHtmlTags(html),
      }
    }
  }

  const isDateInRange = (startDate, endDate) => {
    const today = new Date()
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    const isRange = today >= startDateObj && today <= endDateObj
    return isRange
  }

  const notificationsToDisplay = currentUser.notifications
    .filter((notification) =>
      isDateInRange(
        notification.notificationStartDate,
        notification.notificationEndDate
      )
    )
    .map((notification) => {
      const { header, body } = extractNotification(
        notification.notificationMessage
      )
      return { header, body }
    })

  // The following code implements the GetFeedback
  // The following code needds to be deleted once the GetFeedback alternate is implemented
  // const feedbackLayout = isGetFeedbackEnabled() ? (
  //   <Helmet>
  //      <script type="text/javascript">{GET_FEEDBACK_SCRIPT}</script>
  //   </Helmet>
  // ) : null;

  return (
    <>
      {/* {feedbackLayout} */}
      <div className="full-layout">
        <FlashMessageContainer messages={flashMessages} />
        {successModalMessage && (
          <Modal
            className="modal-inner modal--success"
            onClose={() => hideSuccessModal()}
          >
            <h1>{successModalMessage.title}</h1>
            <p>{successModalMessage.message}</p>
            <div className="modal-actions">
              {hasCustomActions &&
                successModalMessage.actions.map((action) => (
                  <Link
                    key={action.url}
                    className={action.className}
                    to={action.url}
                    onClick={() => hideSuccessModal()}
                  >
                    {action.label}
                  </Link>
                ))}
              <button
                className={hasCustomActions ? 'button-text' : 'button-primary'}
                type="button"
                onClick={() => hideSuccessModal()}
              >
                Close
              </button>
            </div>
          </Modal>
        )}

        <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>
        <Header />
        {notificationsToDisplay && (
          <div className="banner-notification">
            {notificationsToDisplay.map((message, index) => (
              <Notification
                key={index}
                type={NotificationVariant.INFORMATIONAL}
              >
                <span>
                  {message.header && (
                    <div>
                      <p className="text-title--small">{message.header}</p>
                    </div>
                  )}
                  <div>
                    <p className="text-body--medium">{message.body}</p>
                  </div>
                </span>
              </Notification>
            ))}
          </div>
        )}
        <main id="main-content">
          {children}
          <Footer />
        </main>
      </div>
    </>
  )
}

FullLayout.propTypes = propTypes
FullLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    successModalMessage: globalSelectors.successModalMessage(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  showSuccessModal: globalActions.showSuccessModal,
  hideSuccessModal: globalActions.hideSuccessModal,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(FullLayout)
