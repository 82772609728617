function validateDistributionCode() {
  return {
    invalidFormat: {
      regex: '^([A-Za-z][0-9]?|[0-9])$',
      error:
        'Invalid format, must be either a single number, a single letter or a letter and number combination',
    },
    lengthCheck: {
      regex: '^.{1,2}$',
      error: 'Must be 1 to 2 characters',
    },
  }
}

export default validateDistributionCode
