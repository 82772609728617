const ADDITIONAL_INVALID_SSN = ['123-45-6789', '111-11-1111', '333-33-3333']

function validateSSNRegex() {
  return {
    regex: additionalInvalidSsnRegex,
    error: 'Given SSN is not valid',
  }
}

export const additionalInvalidSsnRegex = ADDITIONAL_INVALID_SSN.map(
  (invalidSsn) => `(^${invalidSsn}$)`
).join('|')

export default validateSSNRegex
