import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Table, TableColumn } from 'lp-components'
import { formatCurrency } from 'utils'
import { BD_TRANSANCTION_INFO } from '../../../config/bdTransactionInfoKeys'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.node.isRequired,
  subText: PropTypes.string,
}

const defaultProps = {}

function ParticipantsSummaryTable({ data, title, subText }) {
  return (
    <div className="participant-summary-table">
      <div className="form-header">
        {title}
        <p className="form-paragraph">{subText}</p>
      </div>
      <Table data={data}>
        <TableColumn name="planName" label="Complete legal plan name" />
        <TableColumn name="participants" label="Number of participants" />
        <TableColumn
          name={BD_TRANSANCTION_INFO.AMOUNT}
          label="Total distribution amount"
          format={formatCurrency}
        />
        <TableColumn
          name={BD_TRANSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE}
          label="Inspira fees"
          format={formatCurrency}
        />
        <TableColumn
          name={BD_TRANSANCTION_INFO.TPA_BD_PROCESSING_FEE}
          label="Third-party Fees"
          format={formatCurrency}
        />
        <TableColumn
          name={BD_TRANSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT}
          label="Participant distribution balance"
          format={formatCurrency}
        />
      </Table>
    </div>
  )
}

ParticipantsSummaryTable.propTypes = exact(propTypes)
ParticipantsSummaryTable.defaultProps = defaultProps

export default React.memo(ParticipantsSummaryTable)
