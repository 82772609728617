import { SearchOrderStatus } from '../config/search-services'

export const SupportContactInformation = {
  email: 'RSP_CS_Support@inspirafinancial.com',
  phone: '(833) 923-5220',
}

export const TOAST_ERROR_CONTACT_SUPPORT = `It looks like something went wrong. If the issue continues, please contact support at <a href="mailto:${SupportContactInformation.email}" class="support-email" target="_blank" rel="noopener noreferrer">${SupportContactInformation.email}</a> or call ${SupportContactInformation.phone}.`
export const TOAST_ERROR_CONTACT_SUPPORT_EMAIL = `It looks like something went wrong. If the issue continues, please contact support at <a href="mailto:${SupportContactInformation.email}" class="support-email" target="_blank" rel="noopener noreferrer">${SupportContactInformation.email}</a>.`
export const VIRUS_FILE_ERROR_MESSAGE = `It looks like the file you uploaded is possibly corrupt or using a file format that's not supported. Please try re-uploading.`
export const TOAST_ERROR_CREDIT_CARD = `It looks like something went wrong with processing your credit card. Please review your credit card information. If the issue continues, please contact support at RSP_CS_Support@inspirafinancial.com`
export const TOAST_ERROR_SEARCH_ORDER_PROGRESS = `It looks like your order is In Progress, but something went wrong. If the issue continues, please contact support at RSP_CS_Support@inspirafinancial.com.`
export const SAVED_PAYMENT_METHOD_SUCCESS_MESSAGE =
  'Success! Payment information has been saved in your profile.'

export const NewAgreementSuccessMessages = {
  ELECTRONIC_SIGNATURE:
    'Your services agreement is being generated. Our team will review it within 1-2 business days once it’s signed.',
  WET_SIGNATURE:
    'We’re generating your pre-populated services agreement. It will be available to download shortly on the plan details page.',
  ENTERPRISE_RECORD_KEEPER: `You’re all set!
  <br/>Your agreement was successfully submitted, and you do not need to take any further action. Your recordkeeper will submit your participant data to Inspira.  `,
}
export const SEARCH_SERVICES_AGREEMENT_SUCCESS_MESSAGE =
  'Your Service Agreement is being generated. You will receive a copy via email, then you will be ready to submit Search Orders.'

export const SEARCH_ORDER_SUCCESS_MESSAGE =
  'We received your search order request. We’ll let you know when your results are available to download in the Retirement Services Platform.'

export const SearchOrderTooltip = {
  [SearchOrderStatus.IN_PROGRESS]:
    'Your Search Order is currently in progress. We will notify you by email when the results are ready to be downloaded. Please contact Support if you have any questions regarding the status of your Search Order.',
  [SearchOrderStatus.RESULTS_EXPIRED]:
    'Results for your Search Order are no longer available to download.',
  [SearchOrderStatus.FAILED]:
    'Your Search Order failed to process; please contact Support.',
  [SearchOrderStatus.RESULTS_AVAILABLE]:
    'Your Search Order is complete and results are available to download for 30 days.',
}

export const SEARCH_ORDER_WITH_PAYMENT_SUCCESS_MESSAGE =
  'We received your search order submission. Payment information has been saved to your account.'

export const UPLOAD_AGREEMENT_SUCCESS_MESSAGE =
  'Success! Your Plan Agreement has been received and will be reviewed within 1-2 business days.'

export const INITIAL_INVESTMENT_TOOLTIP_CONTENT =
  'Initial investment of IRA (FDIC-insured cash sweep program or stable value fund).'
export const COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT =
  'Complete Legal Plan name is the official name of the plan that this Services Agreement is being submitted for.'
export const EMPTY_STATE_TOOLTIP_CONTENT = 'Adjust your filters to download.'
export const PERMISSIBLE_USE_TOOLTIP_CONTENT =
  'If your request includes more than one Plan and the permissible uses are not the same for each plan, you must submit a separate search request for each plan.'

export const BD_ADDENDUM_SUCCESS_MESSAGE =
  'Your addendum has been submitted. You can now initiate benefit distributions.'

export const BD_VIEW_SERVICE_AGREEMENT_TOOLTIP_CONTENT =
  'View the plan’s services agreement and benefit distributions addendum on the details page.'
export const BD_ADDENDUM_STATUS_TOOLTIP_CONTENT =
  'Addendum statuses are available once the associated agreement is complete. Combined agreements include the automatic rollover agreement and benefit distributions addendum.'
export const BD_DISTRIBUTION_STATUS_TOOLTIP_CONTENT =
  'Displays the status of a plan’s most recent distribution submission.'
