import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  ScrollErrorForm,
  ConfirmationCheckbox,
  Notification,
  FullPageSpinner,
  ExternalLink,
  BDAddendumLanguageOfAgreement,
} from 'components'
import { FlowActions } from '../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Icon as DownloadIcon } from 'images/download.svg'
import { E_SIGNATURE_CONFIRMATION_TEXT } from 'config/automatic-rollovers'

const propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
}

const defaultProps = {}

const validationSchema = Yup.object({
  confirm: Yup.boolean()
    .required()
    .oneOf(
      [true],
      'To complete your new service agreement submission, you must check the box below'
    ),
  dialogRead: Yup.boolean().required().oneOf([true]),
})

const BD_ADDENDUM_AGREEMENT_URL =
  process.env.REACT_APP_PARTICIPANT_SEARCH_SERVICES_AGREEMENT_URL

function BDAddendumAgreementForm({ initialValues, onSubmit, userDetails }) {
  const serviceAgreementRef = useRef()

  const computeScroll = () => {
    if (serviceAgreementRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        serviceAgreementRef.current
      const scrollDistance = scrollTop + clientHeight
      const isScrolledBottom = Math.abs(scrollHeight - scrollDistance) <= 1

      return isScrolledBottom
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, touched, setFieldValue, isSubmitting }) => {
        return (
          <ScrollErrorForm>
            {isSubmitting && <FullPageSpinner showBackground={true} />}
            <div className="header-bar-content">
              <h3>Benefit distributions addendum</h3>
              <ExternalLink
                link={BD_ADDENDUM_AGREEMENT_URL}
                className="button-text"
              >
                Download full addendum
              </ExternalLink>
              <DownloadIcon className="new" aria-hidden="true" />
            </div>
            {!values.dialogRead && touched.dialogRead && (
              <div>
                <Notification
                  type="error"
                  className="is-error error-scroll-offset"
                >
                  Review entire addendum to continue
                </Notification>
              </div>
            )}
            <div
              className={classNames('scrollable-container', {
                'is-error': !values.dialogRead && touched.dialogRead,
              })}
              onScroll={() => {
                if (values.dialogRead) return
                const isScrolledToBottom = computeScroll()
                if (isScrolledToBottom) {
                  setFieldValue('dialogRead', true)
                }
              }}
              ref={serviceAgreementRef}
              name="dialogRead"
            >
              <BDAddendumLanguageOfAgreement />
            </div>
            <div className="form-header">
              <h3>E-Signature</h3>
            </div>
            <div className="signature-details-container">
              <dl>
                <div>
                  <dt>First name</dt>
                  <dd>{userDetails.firstName}</dd>
                </div>
                <div>
                  <dt>Last name</dt>
                  <dd>{userDetails.lastName}</dd>
                </div>
                <div>
                  <dt>Title</dt>
                  <dd>{userDetails.title}</dd>
                </div>
              </dl>
            </div>
            <ConfirmationCheckbox
              name="confirm"
              label={E_SIGNATURE_CONFIRMATION_TEXT}
              errorText="You must agree to the e-signature disclosure to continue."
            />
            <FlowActions submitContent="Submit" />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

BDAddendumAgreementForm.propTypes = propTypes
BDAddendumAgreementForm.defaultProps = defaultProps

export default BDAddendumAgreementForm
