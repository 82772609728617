import getMaxCharValidator from './getMaxCharValidator'

function getTPAConstraint(userAudience) {
  const externalConstraint = {
    type: 'external',
    validator: 'tpa',
    config: {
      noSlashRegex: {
        regex: '\\/|\\\\',
        error:
          'Please enter a valid Third Party Administrator. No slashes allowed.',
      },
      createMaxCharValidator: getMaxCharValidator(60),
    },
  }

  // ensure TPA field is optional for all recordkeepers
  if (userAudience === 'Recordkeeper') {
    return [externalConstraint]
  }
  return [
    {
      type: 'required',
    },
    externalConstraint,
  ]
}

export default getTPAConstraint
