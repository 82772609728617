// Rather than referencing the entire utils folder, please import individual files
// in order to avoid a circular dependency between config & util files
import generateSelectOptionsFromObject from 'utils/generateSelectOptionsFromObject'
import sortSelectOptions from 'utils/sortSelectOptions'
import { map } from 'lodash'
import { COMPANY_NAME } from 'config/portal'

export const CompanyType = {
  NATIONWIDE: 'Nationwide Retirement Plans',
  TRANSAMERICA: 'Transamerica Retirement Management',
  AMERITAS: 'Ameritas Retirement Plans',
  PENSION: 'Pension, Inc.',
  COREBRIDGE: 'Corebridge Financial formerly AIG Retirement Services',
  STANDARD: 'Standard Insurance Company',
  // VALIC_5 COMPANIES
  AMERICAN: 'American Pension Benefits',
  CORPORATE_1: 'Corporate Financial Service, LLC',
  CORPORATE_2: 'Corporate Financial Services, LLC',
  FM_INTERNATIONAL: 'FM International Services, Ltd',
  FMI_RETIREMENT: 'FMi Retirement Services',
  HILEMAN: 'Hileman Associates',
  HOLZKNECHT: 'HOLZKNECHT UMALI INC',
  HUNNEX: 'Hunnex & Shoemaker',
  METRO_1: 'Metro Benefits Inc',
  METRO_2: 'Metro Benefits, Inc.',
  PA_ADMINISTRATIVE: 'P&A Administrative Services, Inc.',
  RPS: 'Retirement Plan Solutions (RPS)',
  RPS_PA: 'Retirement Plan Solutions - PA',
  WALKER: 'Walker Benefits, Inc./Baker Tilly',
  // LINCOLN COMPANIES
  ASSURED: 'Assured SKCG, Inc.',
  BARCLAY: 'Barclay Planning Group, LLC',
  DANZIGER: 'Danziger & Markhoff LLP',
  DYNAMIC: 'Dynamic Pension Services, Inc',
  FRINGE_BENEFITS: 'Fringe Benefits Design of Minnesota, Inc',
  FUTURE_BENEFITS: 'Future Benefits of America, LLC',
  INVISOR: 'Invisor Solutions LLC',
  ISOLVED: 'iSolved Network',
  LEADING: 'Leading Retirement Solutions',
  MORGAN_STANLEY: 'Morgan Stanley Wealth Management, LLC',
  RIVERSEDGE: 'RiversEdge Advanced Retirement Solutions, LLC',
  SCHNEIDER: 'Schneider Downs Retirement Solutions',
  PAYROLL_COMPANY: 'The Payroll Company is now Isolved',
  USI_CONSULTING: 'USI Consulting Group',
  WIPFLI: 'Wipfli, LLP',
  WORK_PLAN_RETIRE: 'Work Plan Retire',
}
export const InvestmentType = {
  NATIONWIDE: 'Nationwide Plus IRA Fixed Account',
  TRANSAMERICA: 'Transamerica Stable Value Accumulation Option',
  AMERITAS: 'Lincoln Fixed Account',
  VALIC_8: 'VALIC Fixed-Interest Option 922169248',
  VALIC_6: 'VALIC Fixed-Interest Option 922169586',
  VALIC_5: 'VALIC Fixed-Interest Option 922169305',
  STANDARD: 'The Standard APEX',
  DEFAULT: 'Cash sweep',
}
export const valicFiveInvestmentTypes = [
  'American Pension Benefits',
  'Corporate Financial Service, LLC',
  'Corporate Financial Services, LLC',
  'FM International Services, Ltd',
  'FMi Retirement Services',
  'Hileman Associates',
  'HOLZKNECHT UMALI INC',
  'Metro Benefits Inc',
  'Metro Benefits, Inc.',
  'P&A Administrative Services, Inc.',
  'Retirement Plan Solutions (RPS)',
  'Retirement Plan Solutions - PA',
  'Walker Benefits, Inc./Baker Tilly',
  'FM International Services, Ltd.',
]
export const lincolnInvestmentTypes = [
  'Assured SKCG, Inc.',
  'Barclay Planning Group, LLC',
  'Danziger & Markhoff LLP',
  'Dynamic Pension Services, Inc',
  'Fringe Benefits Design of Minnesota, Inc',
  'Future Benefits of America, LLC',
  'Invisor Solutions LLC',
  'iSolved Network',
  'Leading Retirement Solutions',
  'Morgan Stanley Wealth Management, LLC',
  'RiversEdge Advanced Retirement Solutions, LLC',
  'Schneider Downs Retirement Solutions',
  'The Payroll Company is now Isolved',
  'USI Consulting Group',
  'Wipfli, LLP',
  'Work Plan Retire',
]
export const PLAN_ID_REQUIRED_FOR_RECORDKEEPERS = [
  'ADP',
  'Empower',
  'John Hancock Retirement Plan Services',
  'Nationwide Retirement Plans',
  'Transamerica Retirement Management',
]
export const SigningMethod = {
  // values used in the form:
  ELECTRONIC_SIGNATURE: 'Electronic signature',
  WET_SIGNATURE: 'Physical signature',
  // values from BE:
  ELECTRONIC: 'Electronic',
  GENERATE_DOCUMENT: 'Generate Document',
  E_SIGN_RSP: 'E-sign RSP',
}
export const signingMethodOptions = [
  {
    key: SigningMethod.ELECTRONIC_SIGNATURE,
    value: 'electronicSignature',
    description: `Electronically sign the services agreement with DocuSign. Once the agreement has been signed, it will be automatically submitted to ${COMPANY_NAME['short']}.`,
  },
  {
    key: SigningMethod.WET_SIGNATURE,
    value: 'wetSignature',
    description: `Download, sign, and upload the services agreement PDF once you complete the online form.`,
  },
  {
    key: SigningMethod.E_SIGN_RSP,
    value: 'eSignRSP',
    description: null,
  },
]

export const SubmissionMethod = {
  PROCEED_WITH_SUBMISSION: 'Keep participants',
  REMOVE_FROM_SUBMISSION:
    'Remove participants (You may review and download discarded participants prior to submission)',
}

export const ActivePlansSubmissionMethodDB = {
  PROCEED_WITH_SUBMISSION: 'Keep participants',
  REMOVE_FROM_SUBMISSION:
    'Remove participants (You may review and download discarded participants prior to submission)',
}

export const ActivePlansSubmissionMethodDC = {
  PROCEED_WITH_SUBMISSION: 'Keep participants',
  REMOVE_FROM_SUBMISSION:
    'Remove participants (You can choose to discard participants and receive additional instructions)',
}

export const submissionMethodOptions = [
  {
    key: SubmissionMethod.REMOVE_FROM_SUBMISSION,
    value: 'removeFromSubmission',
  },
  {
    key: SubmissionMethod.PROCEED_WITH_SUBMISSION,
    value: 'proceedWithSubmission',
  },
]

export const submissionMethodDBOptions = [
  {
    key: ActivePlansSubmissionMethodDB.REMOVE_FROM_SUBMISSION,
    value: 'removeFromSubmission',
  },
  {
    key: ActivePlansSubmissionMethodDB.PROCEED_WITH_SUBMISSION,
    value: 'proceedWithSubmission',
  },
]

export const submissionMethodDCOptions = [
  {
    key: ActivePlansSubmissionMethodDC.REMOVE_FROM_SUBMISSION,
    value: 'removeFromSubmission',
  },
  {
    key: ActivePlansSubmissionMethodDC.PROCEED_WITH_SUBMISSION,
    value: 'proceedWithSubmission',
  },
]

export const ActiveBenefitsPlanType = {
  DEFINED_BENEFIT: 'Defined benefit',
  DEFINED_CONTRIBUTION: 'Defined contribution',
  NONE: 'None',
}

export const ServicesAgreementStatus = {
  PENDING_SIGNATURES: 'Pending signatures',
  IN_REVIEW: 'In review',
  COMPLETED: 'Completed',
}

export const ServicesAgreementTooltip = {
  [ServicesAgreementStatus.PENDING_SIGNATURES]: 'Agreement not signed',
  [ServicesAgreementStatus.IN_REVIEW]: 'Signed agreement under review',
  [ServicesAgreementStatus.COMPLETED]: 'Agreement review completed',
}
export const ServicesAgreementPlanType = {
  DEFINED_BENEFIT: 'Defined benefit',
  DEFINED_CONTRIBUTION: 'Defined contribution',
}
const planTypeOptions = generateSelectOptionsFromObject(
  ServicesAgreementPlanType
)
export const sortedPlanTypeOptions = sortSelectOptions(planTypeOptions, 'key')
export const planTypeValues = map(planTypeOptions, 'value')

export const SERVICES_AGREEMENT_UPLOAD_INSTRUCTIONS = `Modifications and/or changes to Services Agreement cannot be submitted through the platform and will result in delays in processing your submission. Please reach out to your ${COMPANY_NAME['medium']} relationship manager if you have any questions.`

export const ServicesAgreementPlanStatus = {
  ACTIVE: 'Active',
  TERMINATING: 'Terminating',
}

export const ServicesAgreementPlanStatusForTypes = {
  ACTIVE: 'Active',
  TERMINATING: 'Terminating',
  UNKNOWN: 'Unknown',
}

const planStatusOptions = generateSelectOptionsFromObject(
  ServicesAgreementPlanStatus
)
export const sortedPlanStatusOptions = sortSelectOptions(
  planStatusOptions,
  'key'
)
export const planStatusValues = map(planStatusOptions, 'value')

// submissionType enum (headers) for the services agreement submission api endpoints
// the following diagram provides a visual on which type to use depending on the use case
// https://share.getcloudapp.com/ApuXB80z
export const ServicesAgreementSubmissionType = {
  NEW_AGREEMENT: 'newAgreement',
  EXISTING_AGREEMENT: 'existingAgreement',
  GENERATE_DOCUMENT: 'generateDocument',
  GENERATE_DOCUMENT_EXISTING: 'generateDocumentExisting',
  SEARCH_SERVICES_AGREEMENT: 'searchServicesAgreement',
}

export const ADD_PLAN_SPONSOR_OPTION = {
  key: 'Add New Plan Sponsor',
  value: 'add-plan-sponsor',
}

export const ADD_RECORDKEEPER_OPTION = {
  key: 'Add New Recordkeeper',
  value: 'add-recordkeeper',
}

export const ADD_TPA_OPTION = {
  key: 'Add New TPA',
  value: 'add-tpa',
}

export const ADD_CONSULTANT_OPTION = {
  key: 'Add New Consultant',
  value: 'add-consultant',
}

export const NO_SERVICES_AGREEMENT_OPTION = {
  label: 'No Services Agreement Found',
  value: 'no-services-agreement',
}

// Values that MS expects for contacts submitted
// e.g. contacts submitted through the submit plan agreement
export const AudienceType = {
  PLAN_SPONSOR: 'Plan Sponsor',
  RECORD_KEEPER: 'Record Keeper',
  TPA: 'TPA',
  CONSULTANT: 'Consultant',
}

export const ReasonForDiscard = {
  INVALID_DATA: 'Data Validation Failure',
  MISSING_AGREEMENT: 'Missing Services Agreement',
  TERMINATING_DEFINED_BENEFIT: 'Terminating Defined Benefit Plan',
  ACTIVE_BENEFIT_ABOVE_ALLOWABLE_THRESHOLD:
    'Active Plan with Benefit Above $7,000',
  PROHIBITED_COMPANY: 'Unable to perform search - contact your RM',
}

export const RolloverAccountStatus = {
  ROLLOVER_COMPLETE: 'Rollover complete',
  READY_FOR_FUNDING: 'Ready for funding',
  PROCESSING: 'Processing',
}
export const DistributionAccountStatus = {
  ROLLOVER_COMPLETE: 'Distribution complete',
  READY_FOR_FUNDING: 'Funding received',
  PROCESSING: 'Processing',
}

export const ServicesAgreementParticipantsTooltip = {
  [RolloverAccountStatus.ROLLOVER_COMPLETE]: `This participant's account has been opened and funded.`,
  [RolloverAccountStatus.READY_FOR_FUNDING]: `This participant's account is opened and awaiting funding.`,
  [RolloverAccountStatus.PROCESSING]: `This participant's information has been received, and our team is working to open the participant's account.`,
}

export const RolloverAccountType = {
  CUSTODY: 'Custody',
  IRA: 'IRA',
}
export const E_SIGNATURE_CONFIRMATION_TEXT = `On behalf of myself and any party for which I am signing, I agree that by checking the box, I consent to utilizing electronic signatures for the services agreement and any related documents, and, on behalf of myself and any party for which I am signing, I consent and agree that any agreement, consent or disclosure with ${COMPANY_NAME.medium} for which I apply my name electrically, including by typing my name or applying my electronic signature, is intended to be my legal and valid signature and to bind any party for which I am signing to the terms of such agreement, consent or disclosure. I further confirm that I am authorized to sign for and on behalf of any party for which I am signing.`

export const RolloverType = {
  STANDARD: 'Standard',
  UNCASHED_CHECK: 'Uncashed checks (IRA-eligible)',
}

export const RolloverServices = {
  STANDARD: 'ARO',
  UNCASHED_CHECKS: 'Uncashed-Checks',
}
export const rolloverTypes = [
  {
    key: 'standard',
    value: RolloverType.STANDARD,
    description:
      'Our automatic rollover services help you reconnect your missing or non-responsive former employees with their retirement funds while reducing costs and liabilities for the plan.',
    subDesc:
      'Choose this option to securely transfer your former employees’ funds to a new account.',
  },
  {
    key: 'uncashed-checks',
    value: RolloverType.UNCASHED_CHECK,
    description:
      'Mandatory, small-balanced plan distribution checks can go uncashed by former employees. Generally, uncashed checks representing plan funds can be rolled into Safe Harbor IRAs to help preserve assets and reunite individuals with their accounts.',
    subDesc:
      'Choose this option if you have uncashed checks that need to be rolled over to a new account.',
  },
]

export const AddAddendumRadioGroupOptions = [
  {
    key: `Yes, I'd like to sign an addendum`,
    value: 'yes',
  },
  {
    key: `No, I'm only interested in automatic rollovers`,
    value: 'no',
  },
]
