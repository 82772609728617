import { createAction } from 'redux-actions'

export const setLegaleseConfirmationForm = createAction(
  'SET_LEGALESE_CONFIRMATION_FORM'
)
export const setParticipantsForSubmission = createAction(
  'SET_PARTICIPANTS_FOR_SUBMISSION'
)
export const setDiscardedParticipants = createAction(
  'SET_DISCARDED_PARTICIPANTS'
)
export const resetDiscardedParticipants = createAction(
  'RESET_DISCARDED_PARTICIPANTS'
)
export const setParticipantFileName = createAction('SET_PARTICIPANT_FILE_NAME')
export const setParticipantsAgreementHash = createAction(
  'SET_PARTICIPANTS_AGREEMENT_HASH'
)
export const updateParticipantsWithMappedAgreement = createAction(
  'UPDATE_PARTICIPANTS_WITH_MAPPED_AGREEMENT'
)
export const clearParticipantsData = createAction('CLEAR_PARTICIPANTS_DATA')
export const setCompleteServicesAgreementForm = createAction(
  'SET_COMPLETE_SERVICES_AGREEMENT_FORM'
)
export const clearCompleteServicesAgreementForm = createAction(
  'CLEAR_COMPLETE_SERVICES_AGREEMENT_FORM'
)
export const setPlanConfirmationAcknowledgementsForm = createAction(
  'SET_PLAN_CONFIRMATION_ACKNOWLEDGEMENTS_FORM'
)
export const resetErisapediaSearches = createAction('RESET_ERISAPEDIA_SEARCHES')
export const setRolloverType = createAction('SET_ROLLOVER_TYPE')
export const setAddAddendum = createAction('SET_ADD_ADDENDUM')
