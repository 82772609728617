import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { TableColumn } from 'lp-components'
import { isEqual } from 'lodash'
import {
  PaginatedAndSortableTable,
  TableHeader,
  Tooltip,
  StatusBadge,
  Filter,
  SearchInput,
  TableHeading,
  DownloadTableButton,
} from 'components'
import { PlanAgreementsFilterDialog } from '../components'
import {
  formatDate,
  displayEin,
  dateSorter,
  einSorter,
  formatUrl,
  tippyPlugins,
  displayAttribute,
  useSearchParams,
  convertToSearchParams,
} from 'utils'
import { Link, useHistory } from 'react-router-dom'
import { Path, AroActionItems, AccountAudience } from 'config/portal'
import {
  ServicesAgreementTooltip,
  ServicesAgreementStatus,
} from 'config/automatic-rollovers'
import {
  INITIAL_INVESTMENT_TOOLTIP_CONTENT,
  BD_ADDENDUM_STATUS_TOOLTIP_CONTENT,
  BD_DISTRIBUTION_STATUS_TOOLTIP_CONTENT,
} from 'config/notifications'
import { Icon as InfoIcon } from 'images/info.svg'
import { Icon as PlusIcon } from 'images/plus.svg'
import {
  PlanAgreementsTableHeaders,
  ProductAgreementType,
} from 'config/products'

const { hideOnEsc, hideOnPopperBlur, preventEventBubbling } = tippyPlugins

const INITIAL_FILTERS = {
  agreementStatus: [],
  initialInvestment: [],
  planSponsor: [],
  startLastUpdatedDate: '',
  endLastUpdatedDate: '',
}
const propTypes = {
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary)
    .isRequired,
  noRowsFoundMessage: PropTypes.node,
  handleUploadClick: PropTypes.func.isRequired,
  tableId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    agreementStatus: PropTypes.arrayOf(PropTypes.string),
    initialInvestment: PropTypes.arrayOf(PropTypes.string),
    planSponsor: PropTypes.arrayOf(PropTypes.string),
    startLastUpdatedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endLastUpdatedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }),
  currentUser: Types.user.isRequired,
  agreementsType: PropTypes.string.isRequired,
  fieldMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  noServicesAgreements: PropTypes.bool.isRequired,
}

const defaultProps = {
  noRowsFoundMessage: null,
  filters: null,
}

function PlanAgreementDetailsLinkARO({ data: { serviceAgreementID, name } }) {
  return (
    <td>
      <Link
        to={(location) => {
          return {
            pathname: formatUrl(
              `${Path.SERVICES_AGREEMENTS}/${serviceAgreementID}`
            ),
            state: { search: location.search },
          }
        }}
        className="link-text"
        id="plan-name-link"
      >
        {name}
      </Link>
    </td>
  )
}
function PlanAgreementDetailsLinkBD({ data: { serviceAgreementID, name } }) {
  return (
    <td>
      <Link
        to={(location) => {
          return {
            pathname: formatUrl(
              `${Path.BD_SERVICES_AGREEMENTS}/${serviceAgreementID}`
            ),
            state: { search: location.search },
          }
        }}
        className="link-text"
        id="plan-name-link"
      >
        {name}
      </Link>
    </td>
  )
}

function PlanAgreementsTable({
  servicesAgreements,
  noRowsFoundMessage,
  handleUploadClick,
  tableId,
  filters,
  currentUser,
  agreementsType,
  fieldMappings,
  noServicesAgreements,
}) {
  const history = useHistory()
  const searchParams = useSearchParams()
  const [showFilterModal, setShowFilterModal] = useState(false)
  const hasFiltersApplied = !isEqual(filters, INITIAL_FILTERS)

  const searchValue = searchParams.q ?? ''

  const updateSearchParams = (filters, searchValue) => {
    history.push({
      pathname: location.pathname,
      search: convertToSearchParams({
        ...filters,
        q: searchValue,
      }),
    })
  }
  const isBenefitDistributionsTable = agreementsType === ProductAgreementType.BD
  const disabled = !servicesAgreements?.length
  const showIcon = disabled
  return (
    <>
      <TableHeading
        title={
          agreementsType === ProductAgreementType.ARO
            ? PlanAgreementsTableHeaders.ARO
            : PlanAgreementsTableHeaders.BD
        }
      >
        {/* {!noServicesAgreements &&
          agreementsType === ProductAgreementType.BD && (
            <DownloadTableButton
              data={servicesAgreements}
              fieldMappings={fieldMappings}
              fileName="Inspira Financial_Retirement Services_Benefit Distributions Services Agreements"
              disabled={disabled}
              showIcon={showIcon}
            />
          )} */}
        {!noServicesAgreements &&
          agreementsType === ProductAgreementType.ARO && (
            <DownloadTableButton
              data={servicesAgreements}
              fieldMappings={fieldMappings}
              fileName="Inspira Financial_Retirement Services_ARO Services Agreements"
              disabled={disabled}
              showIcon={showIcon}
            />
          )}
        <div className="input-container" id="services-agreements">
          <SearchInput
            type="search"
            aria-label="Search services agreement"
            placeholder={
              currentUser.accountAudience === AccountAudience.PLAN_FIDUCIARY
                ? 'Search plan name'
                : 'Search plan sponsor, plan name, or EIN'
            }
            onChange={(e) => updateSearchParams(filters, e.target.value)}
            value={searchValue}
          />
          <Filter
            toggleMenu={() => setShowFilterModal((s) => !s)}
            isActive={hasFiltersApplied}
          />
          {showFilterModal && (
            <PlanAgreementsFilterDialog
              filters={filters}
              handleSubmit={(filterValues) =>
                updateSearchParams(filterValues, searchValue)
              }
              clearAllFilters={() =>
                updateSearchParams(INITIAL_FILTERS, searchValue)
              }
              handleClose={() => setShowFilterModal(false)}
              servicesAgreements={servicesAgreements}
              agreementsType={agreementsType}
            />
          )}
        </div>
      </TableHeading>
      <PaginatedAndSortableTable
        data={servicesAgreements}
        initialAscending={false}
        initialColumn="createdDate"
        parentScrollContainerSelector="#main-content"
        className="services-agreements-table"
        noRowsFoundMessage={noRowsFoundMessage}
        tableId={tableId}
      >
        {currentUser.accountAudience !== AccountAudience.PLAN_FIDUCIARY && (
          <TableColumn
            label="Plan sponsor name"
            name="planSponsorCompany.name"
            headerComponent={TableHeader}
            format={displayAttribute}
          />
        )}
        {currentUser.accountAudience !== AccountAudience.PLAN_FIDUCIARY &&
          !isBenefitDistributionsTable && (
            <TableColumn
              label="EIN"
              name="planEIN"
              format={displayEin}
              sortFunc={einSorter}
              headerComponent={TableHeader}
            />
          )}
        <TableColumn
          label="Complete legal plan name"
          name="name"
          component={
            isBenefitDistributionsTable
              ? PlanAgreementDetailsLinkBD
              : PlanAgreementDetailsLinkARO
          }
          headerComponent={TableHeader}
        />

        {isBenefitDistributionsTable ? null : (
          <TableColumn
            label="Initial investment"
            name="portalAgreementType"
            headerComponent={(props) => (
              <TableHeader {...props}>
                <Tooltip
                  content={INITIAL_INVESTMENT_TOOLTIP_CONTENT}
                  ariaLabel="Initial investment information"
                  plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
                >
                  <InfoIcon className="icon-info" />
                </Tooltip>
              </TableHeader>
            )}
            format={displayAttribute}
          />
        )}
        {agreementsType === ProductAgreementType.ARO && (
          <TableColumn
            label="Agreement status"
            name="portalAgreementStatus"
            headerComponent={TableHeader}
            format={displayAttribute}
            component={({ data, value }) => {
              const { name, portalAgreementStatus } = data
              const tooltipContent =
                ServicesAgreementTooltip[portalAgreementStatus]
              if (!portalAgreementStatus)
                return (
                  <td className="status">
                    <span>{value}</span>
                  </td>
                )
              return (
                <td className="status">
                  <Tooltip
                    offset={[0, 18]}
                    content={tooltipContent}
                    ariaLabel={tooltipContent}
                  >
                    <StatusBadge status={portalAgreementStatus} />
                  </Tooltip>
                  {portalAgreementStatus ===
                    ServicesAgreementStatus.PENDING_SIGNATURES && (
                    <button
                      type="button"
                      className="button-text"
                      onClick={() => handleUploadClick(data)}
                      aria-label={`${AroActionItems.UPLOAD_SIGNED_AGREEMENT} for ${name}`}
                    >
                      <PlusIcon aria-hidden="true" />
                      {AroActionItems.UPLOAD_SIGNED_AGREEMENT}
                    </button>
                  )}
                </td>
              )
            }}
          />
        )}
        {agreementsType === ProductAgreementType.BD && (
          <TableColumn
            label="Account number"
            name=""
            headerComponent={(props) => (
              <TableHeader {...props}>
                <Tooltip
                  content={INITIAL_INVESTMENT_TOOLTIP_CONTENT}
                  ariaLabel="Account number information"
                  plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
                >
                  <InfoIcon className="icon-info" />
                </Tooltip>
              </TableHeader>
            )}
          />
        )}
        {isBenefitDistributionsTable && (
          <TableColumn
            label="Addendum status"
            name="planStatus"
            format={(val) => formatDate(val)}
            // sortFunc={dateSorter}
            headerComponent={(props) => (
              <TableHeader {...props}>
                <Tooltip
                  content={BD_ADDENDUM_STATUS_TOOLTIP_CONTENT}
                  ariaLabel="Addendum status"
                  plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
                >
                  <InfoIcon className="icon-info" />
                </Tooltip>
              </TableHeader>
            )}
          />
        )}
        {isBenefitDistributionsTable && (
          <TableColumn
            label="Current distribution status"
            name="portalAgreementStatus"
            format={(val) => formatDate(val)}
            // sortFunc={dateSorter}
            headerComponent={(props) => (
              <TableHeader {...props}>
                <Tooltip
                  content={BD_DISTRIBUTION_STATUS_TOOLTIP_CONTENT}
                  ariaLabel="Current distribution status"
                  plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
                >
                  <InfoIcon className="icon-info" />
                </Tooltip>
              </TableHeader>
            )}
          />
        )}
        <TableColumn
          label="Date created"
          name="createdDate"
          format={(val) => formatDate(val)}
          sortFunc={dateSorter}
          headerComponent={TableHeader}
        />
      </PaginatedAndSortableTable>
    </>
  )
}

PlanAgreementsTable.propTypes = propTypes
PlanAgreementsTable.defaultProps = defaultProps
export default PlanAgreementsTable
