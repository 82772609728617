import { LABELS } from './flatfileLabels'
import getMaxCharValidator from '../utils/getMaxCharValidator'
import getColumnWidth from '../utils/getFlatfileColumnWidth'
import getTPAConstraint from '../utils/getTPAConstraint'
import validateEINRegex from '../utils/validateEIN'
import validatePlanNumber from '../utils/getPlanNumberValidator'
import createNamePrefixValidator from '../utils/getNamePrefixValidator'
import createNotApplicableValidator from '../utils/getNotApplicableValidator'
import validateSSNRegex from '../utils/getSSNValidator'
import { IRA_AMOUNT } from './iraAmount'

export const getUchParticipantFields = (userAudience) => [
  {
    key: 'recordkeeper',
    label: LABELS.RECORDKEEPER,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'recordKeeper',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Recordkeeper. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.RECORDKEEPER),
    },
  },
  {
    key: 'tpa',
    label: LABELS.THIRD_PARTY_ADMINISTRATOR,
    type: 'string',
    constraints: getTPAConstraint(userAudience),
    appearance: {
      size: getColumnWidth(LABELS.THIRD_PARTY_ADMINISTRATOR),
    },
  },
  {
    key: 'planSponsor',
    label: LABELS.PLAN_SPONSOR,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planSponsor',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PLAN_SPONSOR),
    },
  },
  {
    key: 'planName',
    label: LABELS.COMPLETE_LEGAL_PLAN_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planName',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Plan Name. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(80),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.COMPLETE_LEGAL_PLAN_NAME),
    },
  },
  {
    key: 'planEin',
    label: LABELS.EMPLOYER_IDENTIFICATION_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planEin',
        config: validateEINRegex(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.EMPLOYER_IDENTIFICATION_NUMBER),
    },
  },
  {
    key: 'planNumber',
    label: LABELS.THREE_DIGIT_PLAN_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'planNumber',
        config: validatePlanNumber(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.THREE_DIGIT_PLAN_NUMBER),
    },
  },
  {
    key: 'planIdNumber',
    label: LABELS.PROVIDER_PLAN_ID_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'planIdNumber',
        config: {
          createMaxCharValidator: getMaxCharValidator(30),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PROVIDER_PLAN_ID_NUMBER),
    },
  },
  {
    key: 'firstName',
    label: LABELS.FIRST_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'firstName',
        config: {
          createMaxCharValidator: getMaxCharValidator(40),
          checkNamePrefix: createNamePrefixValidator(),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FIRST_NAME),
    },
  },
  {
    key: 'middleName',
    label: LABELS.MIDDLE_NAME,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'middleName',
        config: {
          createMaxCharValidator: getMaxCharValidator(20),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.MIDDLE_NAME),
    },
  },
  {
    key: 'lastName',
    label: LABELS.LAST_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'lastName',
        config: {
          createMaxCharValidator: getMaxCharValidator(60),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.LAST_NAME),
    },
  },
  {
    key: 'addressLine1',
    label: LABELS.ADDRESS_LINE_1,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'addressLine1',
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ADDRESS_LINE_1),
    },
  },
  {
    key: 'addressLine2',
    label: LABELS.ADDRESS_LINE_2,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'addressLine2',
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ADDRESS_LINE_2),
    },
  },
  {
    key: 'city',
    label: LABELS.CITY,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'city',
        config: {
          createMaxCharValidator: getMaxCharValidator(22),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.CITY),
    },
  },
  {
    key: 'state',
    label: LABELS.STATE,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'state',
        config: {
          createMaxCharValidator: getMaxCharValidator(2),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.STATE),
    },
  },
  {
    key: 'zipCode',
    label: LABELS.ZIP_CODE,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'zipCode',
        config: {
          createMaxCharValidator: getMaxCharValidator(10),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ZIP_CODE),
    },
  },
  {
    key: 'countryCode',
    label: LABELS.COUNTRY_CODE,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'countryCode',
        config: {
          createMaxCharValidator: getMaxCharValidator(2),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.COUNTRY_CODE),
    },
  },
  {
    key: 'dob',
    label: LABELS.DATE_OF_BIRTH,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.DATE_OF_BIRTH),
    },
  },
  {
    key: 'ssn',
    label: LABELS.SSN,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'ssn',
        config: {
          ssnValidityCheck: validateSSNRegex(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.SSN),
    },
  },
  {
    key: 'emailAddress',
    label: LABELS.EMAIL_ADDRESS,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'emailAddress',
        config: {
          createMaxCharValidator: getMaxCharValidator(50),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.EMAIL_ADDRESS),
    },
  },
  {
    key: IRA_AMOUNT.TRADITIONAL_PRE_TAX,
    label: LABELS.TRADITIONAL_IRA_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'traditionalIraPreTaxAmount',
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TRADITIONAL_IRA_AMOUNT),
    },
  },
  {
    key: IRA_AMOUNT.ROTH,
    label: LABELS.ROTH_IRA_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'rothIraAmount',
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ROTH_IRA_AMOUNT),
    },
  },
  {
    key: 'dateOfOriginalDistribution',
    label: LABELS.DATE_ORIGIONAL_DISTRIBUTION,
    type: 'string',
    constraints: [
      {
        type: 'external',
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.DATE_ORIGIONAL_DISTRIBUTION),
    },
  },
  {
    key: 'wasTaxWithheld',
    label: LABELS.TAX_WITHHELD,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'wasTaxWithheld',
        config: {
          wasTaxWithheld: {
            regex: '^(?:Y|N)$',
            error: 'Must be Y or N',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TAX_WITHHELD),
    },
  },
]
