import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { AddAddendumRadioGroupOptions } from 'config/automatic-rollovers'
import * as Yup from 'yup'
import { RadioGroup, ScrollErrorForm } from 'components'
import { FlowActions } from '../components'
import { map } from 'lodash'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}
const addAddendumValues = map(AddAddendumRadioGroupOptions, 'value')
function AddAddendumForm({ onSubmit, initialValues }) {
  const addAddendumSchema = Yup.object({
    addAddendum: Yup.string()
      .oneOf(addAddendumValues, 'Select an option to continue')
      .required('Select an option to continue'),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={addAddendumSchema}
    >
      {() => {
        return (
          <ScrollErrorForm>
            <h3>
              Would you like to sign the addendum to add benefit distributions?
            </h3>
            <RadioGroup
              options={AddAddendumRadioGroupOptions}
              name="addAddendum"
              label={false}
              className="radio-container-input"
            />
            <FlowActions submitContent="Next" />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

AddAddendumForm.propTypes = propTypes

export default AddAddendumForm
