function validateEINRegex() {
  return {
    invalidEINFormat: {
      regex: '^[0-9]{2}-[0-9]{7}$',
      error: 'Format must be: XX-XXXXXXX',
    },
    invalidEIN: {
      regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
      error: 'Invalid EIN',
    },
  }
}

export default validateEINRegex
