import {
  ActiveBenefitsPlanType,
  submissionMethodDBOptions,
  submissionMethodDCOptions,
  submissionMethodOptions,
} from '../config/automatic-rollovers'

function getSubmissionMethodOptions(type) {
  if (type === ActiveBenefitsPlanType.DEFINED_BENEFIT)
    return submissionMethodDBOptions
  if (type === ActiveBenefitsPlanType.DEFINED_CONTRIBUTION)
    return submissionMethodDCOptions
  else return submissionMethodOptions
}

export default getSubmissionMethodOptions
