import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, SubmitButton } from 'lp-components'
import { ScrollErrorForm, RadioGroup, ConfirmationCheckbox } from 'components'
import { Formik } from 'formik'
import { displayInteger, getSubmissionMethodOptions } from 'utils'
import * as Yup from 'yup'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  planNames: PropTypes.arrayOf(PropTypes.string),
  activeBenefitsType: PropTypes.string,
  planListTitle: PropTypes.string.isRequired,
  disclosureParagraph: PropTypes.string,
  disclosureParagraphOne: PropTypes.string,
  disclosureParagraphTwo: PropTypes.string,
  checkBoxUndertaking: PropTypes.string,
}

const defaultProps = {
  planNames: [],
}

function IneligiblePlanModal({
  planNames,
  activeBenefitsType,
  planListTitle,
  disclosureParagraph,
  disclosureParagraphOne,
  disclosureParagraphTwo,
  checkBoxUndertaking,
  onClose,
  onContinue,
  children,
}) {
  const [showLegalDisclosure, setShowLegalDisclosure] = useState(false)
  const SubmissionMethodOptions = getSubmissionMethodOptions(activeBenefitsType)
  const validationSchema = Yup.object({
    submissionMethod: Yup.string()
      .oneOf(
        SubmissionMethodOptions.map((option) => option.value),
        `Must be one of the following values: ${SubmissionMethodOptions.map(
          (option) => option.key
        ).join(', ')}`
      )
      .required('Select an option to continue.'),
    confirmLegal: Yup.lazy(() => {
      if (showLegalDisclosure)
        return Yup.boolean()
          .required()
          .oneOf([true], 'Check the disclosure to continue.')
      return Yup.boolean().nullable()
    }),
  })
  return (
    <Formik
      initialValues={{ submissionMethod: '', confirmLegal: false }}
      validationSchema={validationSchema}
      onSubmit={onContinue}
    >
      {() => {
        return (
          <Modal id="ineligible-plan-modal" onClose={onClose}>
            {children}
            <ScrollErrorForm>
              <div className="modal-body">
                <div className="form-body">
                  <div className="form-inner">
                    {disclosureParagraphOne && (
                      <>
                        <p>{disclosureParagraphOne}</p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: disclosureParagraphTwo,
                          }}
                        />
                      </>
                    )}
                    {disclosureParagraph && <p>{disclosureParagraph}</p>}
                    <RadioGroup
                      name="submissionMethod"
                      label="How would you like to proceed with these participants?"
                      options={SubmissionMethodOptions}
                      radioInputProps={{
                        className: 'submission-methods',
                      }}
                      onChange={(e) => {
                        e.target.value === 'proceedWithSubmission'
                          ? setShowLegalDisclosure(true)
                          : setShowLegalDisclosure(false)
                      }}
                    />
                    {showLegalDisclosure && (
                      <ConfirmationCheckbox
                        name="confirmLegal"
                        errorText="Check the disclosure to continue."
                        label={checkBoxUndertaking}
                      />
                    )}
                    {planNames.length > 0 && (
                      <>
                        <div className="plan-list">
                          <h3 className="plan-list__title">{planListTitle}</h3>
                          <span className="text-label--medium">
                            Total participants:{' '}
                            {displayInteger(planNames.length)}
                          </span>
                        </div>
                        <h3>Plan name</h3>
                        <ul>
                          {planNames.map((participant, index) => (
                            <li key={index}>{participant}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="form-actions">
                  <SubmitButton>Continue</SubmitButton>
                </div>
              </div>
            </ScrollErrorForm>
          </Modal>
        )
      }}
    </Formik>
  )
}

IneligiblePlanModal.propTypes = exact(propTypes)
IneligiblePlanModal.defaultProps = defaultProps

export default IneligiblePlanModal
