import { sumBy } from 'lodash'
import { IRA_AMOUNT } from '../config/iraAmount'

// Add up participants plan summary data (used in ARO participant file flow)
// summaryData has the following shape: [ { planName: /string/, participants: /number/, traditionalIraPreTaxAmount: /number/,
// traditionalIraAfterTaxAmount: /number/, rothIraAmount: /number/ } ]
function sumUpParticipantsSummaryData(summaryData) {
  return {
    totalPlans: summaryData.length,
    totalParticipants: sumBy(summaryData, 'participants'),
    [IRA_AMOUNT.TRADITIONAL_PRE_TAX]: sumBy(
      summaryData,
      IRA_AMOUNT.TRADITIONAL_PRE_TAX
    ),
    [IRA_AMOUNT.TRADITIONAL_AFTER_TAX]: sumBy(
      summaryData,
      IRA_AMOUNT.TRADITIONAL_AFTER_TAX
    ),
    [IRA_AMOUNT.ROTH]: sumBy(summaryData, IRA_AMOUNT.ROTH),
  }
}

export default sumUpParticipantsSummaryData
