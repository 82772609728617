import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import { ProductExitModal } from 'components'

const propTypes = {
  contentText: PropTypes.string,
}

const defaultProps = {
  contentText:
    'You have unsaved changes. Are you sure you want to quit and exit?',
}

// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
function ExitConfirmPrompt({ contentText }) {
  const [lastLocation, setLastLocation] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const handleBlockedNavigation = (nextLocation) => {
    if (confirmedNavigation) return true

    const rootPath = location.pathname.split('/', 2).join('/')
    if (
      !nextLocation.state?.ignorePrompt &&
      !nextLocation.pathname.startsWith(rootPath)
    ) {
      setLastLocation(nextLocation)
      setShowModal(true)

      return false // blocks navigation
    }

    return true
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation)
    }
  }, [lastLocation, confirmedNavigation])

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation} />
      {showModal && (
        <ProductExitModal
          className="exit-confirm-prompt"
          onClose={() => setShowModal(false)}
          onConfirm={() => setConfirmedNavigation(true)}
          confirmContent="Yes, continue"
        >
          <h2>Are you sure you want to cancel?</h2>
          <p>{contentText}</p>
        </ProductExitModal>
      )}
    </>
  )
}

ExitConfirmPrompt.propTypes = propTypes
ExitConfirmPrompt.defaultProps = defaultProps

export default ExitConfirmPrompt
