import { groupBy, map } from 'lodash'
import { IRA_AMOUNT } from '../config/iraAmount'
import { convertNumber } from 'utils'

// Summarize an array of participant records by plan (used in ARO participant file flow)
// The summary captures the following per plan: 1) total number of participants and 2) total rollover amount per account:
// a) traditional IRA pre-tax, b) traditional IRA post-tax, c) roth IRA
function summarizeParticipantsDataByPlan(participantsData) {
  const participantsHash = groupBy(
    participantsData,
    (participant) => participant.mappedPlanName || participant.planName
  )

  return map(participantsHash, (records, planName) => {
    return {
      planName,
      participants: records.length,
      [IRA_AMOUNT.TRADITIONAL_PRE_TAX]: sumUpRecordsAccount(
        records,
        IRA_AMOUNT.TRADITIONAL_PRE_TAX
      ),
      [IRA_AMOUNT.TRADITIONAL_AFTER_TAX]: sumUpRecordsAccount(
        records,
        IRA_AMOUNT.TRADITIONAL_AFTER_TAX
      ),
      [IRA_AMOUNT.ROTH]: sumUpRecordsAccount(records, IRA_AMOUNT.ROTH),
    }
  })
}

// ----- PRIVATE -----

const sumUpRecordsAccount = (records, accountType) => {
  return records.reduce(
    (total, record) => total + convertNumber(record[accountType]),
    0
  )
}

export default summarizeParticipantsDataByPlan
