import React from 'react'
import PropTypes from 'prop-types'
import { AddAddendumForm } from '../forms'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { Notification, ExternalLink } from 'components'
import { Variant as NotificationVariant } from 'components/Notification'

const propTypes = {
  setAddAddendum: PropTypes.func,
  addAddendum: PropTypes.string,
}

const BD_FEE_SCHEDULE_URL = process.env.REACT_APP_BD_FEE_SHCEDULE_URL

function AddAddendumView({ setAddAddendum, addAddendum }) {
  const history = useHistory()

  return (
    <div className="card full-height">
      <div className="form-masthead">
        <h2>Add a benefit distributions addendum</h2>
      </div>
      <div className="form-section--with-top-border">
        <p>
          Benefit distributions at Inspira make it easy to initiate and manage
          bulk distributions, ensuring participants receive their funds on time.
        </p>
        <p>
          You can add benefit distributions to your available products by
          signing an addendum with your automatic rollover agreement. Even if
          you’re not planning to use benefit distributions right away, signing
          now can save you time by avoiding the need to create an addendum
          later.
        </p>
        <p>
          Keep in mind, fees may apply when initiating benefit distributions.
          <ExternalLink
            link={BD_FEE_SCHEDULE_URL}
            className="link-text"
            aria-label="Bd fee schedule"
          >
            Download fee schedule
          </ExternalLink>
        </p>
        <Notification type={NotificationVariant.WARNING}>
          <p className="text-title--small">
            Note that we only process distributions that are eligible for
            automatic rollover to an IRA. This typically includes participants
            from terminating defined contribution plans or unresponsive
            participants from active plans with balances of $7,000 or less.
          </p>
        </Notification>
      </div>
      <div className="add-addendum-form">
        <AddAddendumForm
          initialValues={{ addAddendum: addAddendum || '' }}
          onSubmit={({ addAddendum }) => {
            setAddAddendum(addAddendum)
            history.push({
              pathname: '/automatic-rollovers/complete-services-agreement',
              state: {
                search: history.location.state?.search,
              },
            })
          }}
        />
      </div>
    </div>
  )
}

AddAddendumView.propTypes = propTypes

function mapStateToProps(state) {
  return {
    addAddendum: selectors.addAddendum(state),
  }
}

const mapDispatchToProps = {
  setAddAddendum: actions.setAddAddendum,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddAddendumView
)
