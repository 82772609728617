import { LABELS } from './flatfileLabels'
import getMaxCharValidator from '../utils/getMaxCharValidator'
import getColumnWidth from '../utils/getFlatfileColumnWidth'
import validateEINRegex from '../utils/validateEIN'
import validatePlanNumber from '../utils/getPlanNumberValidator'
import createNamePrefixValidator from '../utils/getNamePrefixValidator'
import createNotApplicableValidator from '../utils/getNotApplicableValidator'
import validateSSNRegex from '../utils/getSSNValidator'
import { BD_TRANSANCTION_INFO } from './bdTransactionInfoKeys'
import validateDistributionCode from '../utils/getDistributionCodeValidator'

export const getBdParticipantFields = () => [
  {
    key: 'recordkeeper',
    label: LABELS.RECORDKEEPER,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'recordKeeper',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Recordkeeper. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.RECORDKEEPER),
    },
  },
  {
    key: 'tpa',
    label: LABELS.THIRD_PARTY_ADMINISTRATOR,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'tpa',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error:
              'Please enter a valid Third Party Administrator. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.THIRD_PARTY_ADMINISTRATOR),
    },
  },
  {
    key: 'planSponsor',
    label: LABELS.PLAN_SPONSOR,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planSponsor',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(60),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PLAN_SPONSOR),
    },
  },
  {
    key: 'planName',
    label: LABELS.COMPLETE_LEGAL_PLAN_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planName',
        config: {
          noSlashRegex: {
            regex: '\\/|\\\\',
            error: 'Please enter a valid Plan Name. No slashes allowed.',
          },
          createMaxCharValidator: getMaxCharValidator(80),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.COMPLETE_LEGAL_PLAN_NAME),
    },
  },
  {
    key: 'planEin',
    label: LABELS.EMPLOYER_IDENTIFICATION_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'planEin',
        config: validateEINRegex(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.EMPLOYER_IDENTIFICATION_NUMBER),
    },
  },
  {
    key: 'planNumber',
    label: LABELS.THREE_DIGIT_PLAN_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'planNumber',
        config: validatePlanNumber(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.THREE_DIGIT_PLAN_NUMBER),
    },
  },
  {
    key: 'firstName',
    label: LABELS.FIRST_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'firstName',
        config: {
          createMaxCharValidator: getMaxCharValidator(40),
          checkNamePrefix: createNamePrefixValidator(),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FIRST_NAME),
    },
  },
  {
    key: 'middleName',
    label: LABELS.MIDDLE_NAME,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'middleName',
        config: {
          createMaxCharValidator: getMaxCharValidator(20),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.MIDDLE_NAME),
    },
  },
  {
    key: 'lastName',
    label: LABELS.LAST_NAME,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'lastName',
        config: {
          createMaxCharValidator: getMaxCharValidator(60),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.LAST_NAME),
    },
  },
  {
    key: 'addressLine1',
    label: LABELS.ADDRESS_LINE_1,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'addressLine1',
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
          checkNotApplicable: createNotApplicableValidator(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ADDRESS_LINE_1),
    },
  },
  {
    key: 'addressLine2',
    label: LABELS.ADDRESS_LINE_2,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'addressLine2',
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ADDRESS_LINE_2),
    },
  },
  {
    key: 'city',
    label: LABELS.CITY,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'city',
        config: {
          createMaxCharValidator: getMaxCharValidator(22),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.CITY),
    },
  },
  {
    key: 'state',
    label: LABELS.STATE,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.STATE),
    },
  },
  {
    key: 'zipCode',
    label: LABELS.ZIP_CODE,
    type: 'string',
    constraints: [],
    appearance: {
      size: getColumnWidth(LABELS.ZIP_CODE),
    },
  },
  {
    key: 'countryCode',
    label: LABELS.COUNTRY_CODE,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.COUNTRY_CODE),
    },
  },
  {
    key: 'phone',
    label: LABELS.PHONE_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'phone',
        config: {
          phoneNumber: {
            regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
            error:
              'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PHONE_NUMBER),
    },
  },
  {
    key: 'dob',
    label: LABELS.DATE_OF_BIRTH,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.DATE_OF_BIRTH),
    },
  },
  {
    key: 'ssn',
    label: LABELS.SSN,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: 'ssn',
        config: {
          ssnValidityCheck: validateSSNRegex(),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.SSN),
    },
  },
  {
    key: 'emailAddress',
    label: LABELS.EMAIL_ADDRESS,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: 'emailAddress',
        config: {
          createMaxCharValidator: getMaxCharValidator(50),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.EMAIL_ADDRESS),
    },
  },
  {
    key: BD_TRANSANCTION_INFO.TRANSACTION_TYPE,
    label: LABELS.TRANSACTION_TYPE_FOR_BD,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TRANSACTION_TYPE,
        config: {
          transactionType: {
            regex: '^(?:check|wire|ACH|check/overnight delivery)$',
            error: 'Must be check, wire, ACH, or check/overnight delivery',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TRANSACTION_TYPE_FOR_BD),
    },
  },
  {
    key: BD_TRANSANCTION_INFO.PAYMENT_INSTRUCTIONS,
    label: LABELS.PAYMENT_INSTRUCTIONS_FOR_BD,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.PAYMENT_INSTRUCTIONS,
        config: {
          paymentInstructions: {
            regex: '^(?:direct to participant|direct rollover)$',
            error: `Must be 'direct to participant' or 'direct rollover'`,
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PAYMENT_INSTRUCTIONS_FOR_BD),
    },
  },
  {
    key: BD_TRANSANCTION_INFO.AMOUNT,
    label: LABELS.AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.AMOUNT,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.AMOUNT),
    },
  },
  {
    key: BD_TRANSANCTION_INFO.TPA_BD_PROCESSING_FEE,
    label: LABELS.TPA_BD_PROCESSING_FEE,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TPA_BD_PROCESSING_FEE,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TPA_BD_PROCESSING_FEE),
    },
  },
  {
    label: LABELS.INSPIRA_BD_PROCESSING_FEE,
    key: BD_TRANSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.INSPIRA_BD_PROCESSING_FEE),
    },
  },
  {
    label: LABELS.GROSS_DISTRIBUTION_AMOUNT,
    key: BD_TRANSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.GROSS_DISTRIBUTION_AMOUNT),
    },
  },
  {
    label: LABELS.WIRE_ACH_1,
    key: BD_TRANSANCTION_INFO.WIRE_ACH_1,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.WIRE_ACH_1,
        config: {
          createMaxCharValidator: getMaxCharValidator(22),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.WIRE_ACH_1),
    },
  },
  {
    label: LABELS.ABA_ROUTING,
    key: BD_TRANSANCTION_INFO.ABA_ROUTING,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.ABA_ROUTING,
        config: {
          abaRouting: {
            regex: '^[0-9]{9}$',
            error:
              'Nine-digit number only, social security number (SSN) unaccepted',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ABA_ROUTING),
    },
  },
  {
    label: LABELS.WIRE_ACH_2,
    key: BD_TRANSANCTION_INFO.WIRE_ACH_2,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.WIRE_ACH_2,
        config: {
          wireAch2: {
            regex: '^(?=.*[0-9]).+$',
            error: 'String must have at least one numeric character',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.WIRE_ACH_2),
    },
  },
  {
    label: LABELS.WIRE_ACH_3,
    key: BD_TRANSANCTION_INFO.WIRE_ACH_3,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.WIRE_ACH_3,
        config: {
          wireAch3: {
            regex: '^[0-9]{9}$',
            error: 'Nine-digit number only',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.WIRE_ACH_3),
    },
  },
  {
    label: LABELS.FFC_NAME,
    key: BD_TRANSANCTION_INFO.FFC_NAME,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.FFC_NAME,
        config: {
          ffcName: {
            regex: '^.*[A-Za-z].*$',
            error: 'String must have at least one alpha character',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FFC_NAME),
    },
  },
  {
    label: LABELS.FFC_NUMBER,
    key: BD_TRANSANCTION_INFO.FFC_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.FFC_NUMBER,
        config: {
          ffcNumber: {
            regex: '^(?=.*[0-9]).+$',
            error: 'Must have at least one numeric character.',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FFC_NUMBER),
    },
  },
  {
    label: LABELS.ACCOUNT_TYPE,
    key: BD_TRANSANCTION_INFO.ACCOUNT_TYPE,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.ACCOUNT_TYPE,
        config: {
          accountType: {
            regex: '^(?:checking|savings)$',
            error: `Must be 'checking' or 'savings'`,
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ACCOUNT_TYPE),
    },
  },
  {
    label: LABELS.CHECK_PAYABLE_INFO,
    key: BD_TRANSANCTION_INFO.CHECK_PAYABLE_INFO,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.CHECK_PAYABLE_INFO,
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.CHECK_PAYABLE_INFO),
    },
  },
  {
    label: LABELS.ACCOUNT_NUMBER,
    key: BD_TRANSANCTION_INFO.ACCOUNT_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.ACCOUNT_NUMBER,
        config: {
          accountNumber: {
            regex: '^(?=.*[0-9]).+$',
            error: 'Must have at least one numeric character',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ACCOUNT_NUMBER),
    },
  },
  {
    label: LABELS.ADDRESS_FOR_CHECK,
    key: BD_TRANSANCTION_INFO.ADDRESS_FOR_CHECK,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.ADDRESS_FOR_CHECK,
        config: {
          createMaxCharValidator: getMaxCharValidator(36),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ADDRESS_FOR_CHECK),
    },
  },
  {
    label: LABELS.CITY_FOR_CHECK,
    key: BD_TRANSANCTION_INFO.CITY_FOR_CHECK,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.CITY_FOR_CHECK,
        config: {
          createMaxCharValidator: getMaxCharValidator(22),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.CITY_FOR_CHECK),
    },
  },
  {
    label: LABELS.STATE_FOR_CHECK,
    key: BD_TRANSANCTION_INFO.STATE_FOR_CHECK,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.STATE_FOR_CHECK,
        config: {
          createMaxCharValidator: getMaxCharValidator(22),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.STATE_FOR_CHECK),
    },
  },
  {
    label: LABELS.ZIP_FOR_CHECK,
    key: BD_TRANSANCTION_INFO.ZIP_FOR_CHECK,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.ZIP_FOR_CHECK,
        config: {
          createMaxCharValidator: getMaxCharValidator(10),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.ZIP_FOR_CHECK),
    },
  },
  {
    label: LABELS.GROSS_DIST,
    key: BD_TRANSANCTION_INFO.GROSS_DIST,
    type: 'string',
    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.GROSS_DIST,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.GROSS_DIST),
    },
  },
  {
    label: LABELS.TAXABLE_AMOUNT,
    key: BD_TRANSANCTION_INFO.TAXABLE_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TAXABLE_AMOUNT,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TAXABLE_AMOUNT),
    },
  },
  {
    label: LABELS.TAXABLE_AMOUNT_NOT_DET,
    key: BD_TRANSANCTION_INFO.TAXABLE_AMOUNT_NOT_DET,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TAXABLE_AMOUNT_NOT_DET,
        config: {
          taxableAmountNotDetermined: {
            regex: '^X?$',
            error: `Value must be 'X' or blank`,
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TAXABLE_AMOUNT_NOT_DET),
    },
  },
  {
    label: LABELS.TOTAL_DISTRIBUTION,
    key: BD_TRANSANCTION_INFO.TOTAL_DISTRIBUTION,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TOTAL_DISTRIBUTION,
        config: {
          totalDistribution: {
            regex: '^X?$',
            error: `Value must be 'X' or blank`,
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TOTAL_DISTRIBUTION),
    },
  },
  {
    label: LABELS.CAPITAL_GAIN,
    key: BD_TRANSANCTION_INFO.CAPITAL_GAIN,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.CAPITAL_GAIN,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.CAPITAL_GAIN),
    },
  },
  {
    label: LABELS.FEDERAL_INCOME_TAX_W,
    key: BD_TRANSANCTION_INFO.FEDERAL_INCOME_TAX_W,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.FEDERAL_INCOME_TAX_W,
        config: {
          createMaxCharValidator: getMaxCharValidator(7),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FEDERAL_INCOME_TAX_W),
    },
  },
  {
    label: LABELS.FEDERAL_INCOME_TAX_W_AMOUNT,
    key: BD_TRANSANCTION_INFO.FEDERAL_INCOME_TAX_W_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.FEDERAL_INCOME_TAX_W_AMOUNT,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FEDERAL_INCOME_TAX_W_AMOUNT),
    },
  },
  {
    label: LABELS.EMPLOYEE_CONTRIBUTIONS,
    key: BD_TRANSANCTION_INFO.EMPLOYEE_CONTRIBUTIONS,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.EMPLOYEE_CONTRIBUTIONS,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.EMPLOYEE_CONTRIBUTIONS),
    },
  },
  {
    label: LABELS.NET_APPRECIATION,
    key: BD_TRANSANCTION_INFO.NET_APPRECIATION,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.NET_APPRECIATION,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.NET_APPRECIATION),
    },
  },
  {
    label: LABELS.DISTRIBUTION_CODES,
    key: BD_TRANSANCTION_INFO.DISTRIBUTION_CODES,
    type: 'string',

    constraints: [
      {
        type: 'required',
      },
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.DISTRIBUTION_CODES,
        config: validateDistributionCode(),
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.DISTRIBUTION_CODES),
    },
  },
  {
    label: LABELS.PERCENTAGE_TOTAL_DIST,
    key: BD_TRANSANCTION_INFO.PERCENTAGE_TOTAL_DIST,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.PERCENTAGE_TOTAL_DIST,
        config: {
          createMaxCharValidator: getMaxCharValidator(7),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PERCENTAGE_TOTAL_DIST),
    },
  },
  {
    label: LABELS.TOTAL_EMPLOYEE_CONTRIBUTIONS,
    key: BD_TRANSANCTION_INFO.TOTAL_EMPLOYEE_CONTRIBUTIONS,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.TOTAL_EMPLOYEE_CONTRIBUTIONS,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.TOTAL_EMPLOYEE_CONTRIBUTIONS),
    },
  },
  {
    label: LABELS.AMOUNT_ALLOCABLE,
    key: BD_TRANSANCTION_INFO.AMOUNT_ALLOCABLE,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.AMOUNT_ALLOCABLE,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.AMOUNT_ALLOCABLE),
    },
  },
  {
    label: LABELS.FIRST_YEAR_OF_DESIG,
    key: BD_TRANSANCTION_INFO.FIRST_YEAR_OF_DESIG,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.FIRST_YEAR_OF_DESIG,
        config: {
          firstYearContrib: {
            regex: '^(19[0-9]{2}|20[0-9]{2})$',
            error: 'Format must be: CCYY',
          },
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.FIRST_YEAR_OF_DESIG),
    },
  },
  {
    label: LABELS.STATE_TAX_W,
    key: BD_TRANSANCTION_INFO.STATE_TAX_W,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.STATE_TAX_W,
        config: {
          createMaxCharValidator: getMaxCharValidator(7),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.STATE_TAX_W),
    },
  },
  {
    label: LABELS.STATE_TAX_W_AMOUNT,
    key: BD_TRANSANCTION_INFO.STATE_TAX_W_AMOUNT,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.STATE_TAX_W_AMOUNT,
        config: {
          createMaxCharValidator: getMaxCharValidator(14),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.STATE_TAX_W_AMOUNT),
    },
  },
  {
    label: LABELS.PAYER_STATE_NUMBER,
    key: BD_TRANSANCTION_INFO.PAYER_STATE_NUMBER,
    type: 'string',
    constraints: [
      {
        type: 'external',
        validator: BD_TRANSANCTION_INFO.PAYER_STATE_NUMBER,
        config: {
          createMaxCharValidator: getMaxCharValidator(10),
        },
      },
    ],
    appearance: {
      size: getColumnWidth(LABELS.PAYER_STATE_NUMBER),
    },
  },
]
